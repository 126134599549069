import React from 'react';
import DocumentTitle from 'react-document-title';
import { observer } from 'mobx-react-lite';

import { RootStoreModel } from '@src/mobx/stores/Root';

import { useInject } from '@src/utils/hooks/mobx';

type Props = {
  title: string;
  children?: JSX.Element;
};

const mapStore = ({ user, organization }: RootStoreModel) => ({
  user,
  organization,
});

const PageTitle = observer(({ title, children }: Props) => {
  const { organization } = useInject(mapStore);
  // Page title is made of substrings joined together by dashes. We filter out the empty/null ones.
  const formattedTitle = [title, organization.name, 'WeHealth Community Tracing Portal'].filter(Boolean).join(' - ');

  return <DocumentTitle title={formattedTitle}>{children}</DocumentTitle>;
});

export default PageTitle;
