export default {
  colors: {
    darkGray: '#383838',
    darkMediumGray: '#828282',
    darkLiver: '#4F4F4F',
    lightMediumGray: '#bdbdbd',
    lightGray: '#e0e0e0',
    mediumGray: '#EBEEF2',
    white: '#ffffff',
    whiteSmoke: '#EFEFEF',
    bluejay: '#2c58b1',
    aqua: '#43c4d9',
    tangerine: '#f05452',
    safetyOrange: '#FF7800',
  },
};
