import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Collapse, Icon, IconButton, Typography } from '@material-ui/core';
import { MRRequest, MRAction } from '@src/lib/types';

import {
  create as createRequest,
  publish as publishRequest,
  requests as getRequests,
  revert as revertRequest,
} from '@src/apis/messagingRelease';

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '@src/components/PageTitle';

import { observer } from 'mobx-react-lite';
import { useInject } from '@src/utils/hooks/mobx';
import { RootStoreModel } from '@src/mobx/stores/Root';

const HeroContainer = styled.div`
  min-height: 248px;
  background-color: ${(p) => p.theme.colors.bluejay};
  display: flex;
  align-items: center;
`;

const HeroParagraph = styled.span`
  color: ${(p) => p.theme.colors.white};
  font-size: 23px;
  line-height: 36px;
`;

const RequestsContainer = styled.div`
  padding: 100px 100px 100px 100px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f4f6f9;
`;

const DEFAULT_ERROR_MESSAGE = '';

const mapStore = ({ user, organization }: RootStoreModel) => ({
  user,
  organization,
});

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { AxiosError } from 'axios';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  collapseTableRow: {
    height: 0,
  },
  statusOk: {
    backgroundColor: 'green',
  },
  statusInfo: {
    backgroundColor: 'yellow',
  },
  statusWarning: {
    backgroundColor: 'red',
  },
  publishToProd: {
    backgroundColor: 'lightBlue',
  },
  publishToDemo: {
    backgroundColor: 'lightGreen',
  },
  removeFromDemo: {
    backgroundColor: 'lightCyan',
  },
  removeFromProd: {
    backgroundColor: 'lightCoral',
  },
});

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const isDevEnv = (process.env.NODE_ENV || 'development') == 'development';

const ManageInAppMessaging = observer(() => {
  const { user } = useInject(mapStore);
  const inputFile = React.useRef<HTMLInputElement>(null);

  const errorToastRef = React.useRef<any>(null);
  const [errorMessage] = React.useState(DEFAULT_ERROR_MESSAGE);
  const { data: requests, refetch: refetchRequests } = useQuery('requests', getRequests);
  const [selectedFile, setSelectedFile] = React.useState<any>(undefined);

  const {
    data: createdRequest,
    isFetching: isCreatingRequest,
    refetch: startRequest,
  } = useQuery('createRequest', () => createRequest(selectedFile), {
    enabled: false,
    retry: false,
    refetchOnMount: false,
    onError: (error: AxiosError) => {
      setCreateRequestErrorMessage(error?.response?.data?.error);
    },
  });
  const [createRequestErrorMessage, setCreateRequestErrorMessage] = React.useState<string | undefined>(undefined);
  const [processingMessagingReleaseRequest, setProcessingMessagingReleaseRequest] = React.useState(false);

  React.useEffect(() => {
    if (errorMessage && errorToastRef.current) {
      errorToastRef.current.show();
    }
  }, [errorMessage]);

  React.useEffect(() => {
    if (createdRequest) {
      updateRequests();
    }
  }, [createdRequest]);

  React.useEffect(() => {
    updateRequests();
    setProcessingMessagingReleaseRequest(isCreatingRequest);
  }, [isCreatingRequest]);

  React.useEffect(() => {
    if (selectedFile) {
      startRequest();
    }
  }, [selectedFile]);

  //TODO: Remove this when we have a proper background function or another way to ran a parallel
  // execution while the request creation has started
  const updateRequests = async (): Promise<number> => {
    await new Promise((f) => setTimeout(f, 1000));
    refetchRequests();
    return 0;
  };

  const onFileChange = async (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
    setSelectedFile(null);
  };

  function Row(props: { row: MRRequest }) {
    const { row: request } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
      <React.Fragment>
        <TableRow className={classes.root} hover={true}>
          {/* Date/Time */}
          <TableCell onClick={() => setOpen(!open)}>
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>

            <span title={'request id: '.concat(`${request.id}`)}>{new Date(request.created).toLocaleString()}</span>
          </TableCell>

          {/* Requester */}
          <TableCell>{request.requester}</TableCell>

          {/* Community */}
          <TableCell>
            {request.targets?.map((messaging, index) => (
              <div key={`${messaging.language}-${index}`}>
                {`${messaging.community}`} -&gt; {`${messaging.language}`}
              </div>
            ))}
          </TableCell>

          {/* Filename */}
          <TableCell>
            <a href={request.inputFile?.url} target="_blank">
              {request.inputFile?.name}
            </a>
          </TableCell>

          {/* Status */}
          {request.status === 'ReleasedToDemo' ||
          request.status === 'ReleasedToProd' ||
          request.status === 'SuccessfullyProcessed' ? (
            <TableCell className={classes.statusOk}>
              <Icon title={request.error ? `${request.status}:${request.error}` : request.status}>
                <CheckCircleIcon fontSize="large" />
              </Icon>
            </TableCell>
          ) : request.status === 'Processing' ? (
            <TableCell className={classes.statusInfo}>
              <Icon title={request.error ? `${request.status}:${request.error}` : request.status}>
                <InfoIcon fontSize="large" />
              </Icon>
            </TableCell>
          ) : request.status === 'FailedToProcess' ? (
            <TableCell className={classes.statusWarning}>
              <Icon title={request.error ? `${request.status}:${request.error}` : request.status}>
                <WarningIcon fontSize="large" />
              </Icon>
            </TableCell>
          ) : (
            <TableCell />
          )}

          {/* Logs */}
          <TableCell>
            {request.logFileUrl && (
              <a href={request.logFileUrl} target="_blank">
                View Log
              </a>
            )}
          </TableCell>

          {/* Actions */}
          <TableCell>
            <Box>
              {request.status == 'ReleasedToDemo' && (
                <p>
                  <Button
                    disabled={processingMessagingReleaseRequest}
                    onClick={() => {
                      setProcessingMessagingReleaseRequest(true);
                      publishRequest(request.id, 'prod')
                        .then(() => {
                          refetchRequests();
                          setProcessingMessagingReleaseRequest(false);
                        })
                        .catch((error) => {
                          setCreateRequestErrorMessage(error?.response?.data?.error);
                          setProcessingMessagingReleaseRequest(false);
                        });
                    }}
                    className={classes.publishToProd}
                  >
                    Publish to prod
                  </Button>
                </p>
              )}

              {request.status == 'SuccessfullyProcessed' && (
                <p>
                  <Button
                    disabled={processingMessagingReleaseRequest}
                    onClick={() => {
                      setProcessingMessagingReleaseRequest(true);
                      publishRequest(request.id, 'demo')
                        .then(() => {
                          refetchRequests();
                          setProcessingMessagingReleaseRequest(false);
                        })
                        .catch((error) => {
                          setCreateRequestErrorMessage(error?.response?.data?.error);
                          setProcessingMessagingReleaseRequest(false);
                        });
                    }}
                    className={classes.publishToDemo}
                  >
                    Publish to demo
                  </Button>
                </p>
              )}

              {isDevEnv && request.status == 'ReleasedToDemo' && request.testing && (
                <p>
                  <Button
                    disabled={processingMessagingReleaseRequest}
                    onClick={() => {
                      setProcessingMessagingReleaseRequest(true);
                      revertRequest(request.id, 'demo')
                        .then(() => {
                          refetchRequests();
                          setProcessingMessagingReleaseRequest(false);
                        })
                        .catch((error) => {
                          setCreateRequestErrorMessage(error?.response?.data?.error);
                          setProcessingMessagingReleaseRequest(false);
                        });
                    }}
                    className={classes.removeFromDemo}
                  >
                    Remove from demo
                  </Button>
                </p>
              )}

              {isDevEnv && request.status == 'ReleasedToProd' && request.testing && (
                <p>
                  <Button
                    disabled={processingMessagingReleaseRequest}
                    onClick={() => {
                      setProcessingMessagingReleaseRequest(true);
                      revertRequest(request.id, 'prod')
                        .then(() => {
                          refetchRequests();
                          setProcessingMessagingReleaseRequest(false);
                        })
                        .catch((error) => {
                          setCreateRequestErrorMessage(error?.response?.data?.error);
                          setProcessingMessagingReleaseRequest(false);
                        });
                    }}
                    className={classes.removeFromProd}
                  >
                    Remove from prod
                  </Button>
                </p>
              )}

              {request.status == 'FailedToProcess' && <p>Try Again</p>}

              {request.status == 'Processing' && <p>Please Wait</p>}
            </Box>
          </TableCell>
        </TableRow>

        <TableRow className={classes.collapseTableRow}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Box style={{ paddingLeft: 20 }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                    {'Request ID: '.concat(`${request.id}`)}
                  </Typography>
                </Box>
                {request.actions && Object.values(request.actions).length > 0 && (
                  <Box margin={1}>
                    <Typography variant="h6" gutterBottom component="div">
                      Actions History
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Requester</TableCell>
                          <TableCell>Description</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.values(request.actions)
                          .sort((a1: MRAction, a2: MRAction) => a1.date - a2.date)
                          .map((actionRow: MRAction, index: Number) => (
                            <TableRow key={`action-history-${request.id}-${index}`}>
                              <TableCell>{new Date(actionRow.date).toLocaleString()}</TableCell>
                              <TableCell>{actionRow.requester}</TableCell>
                              <TableCell>{actionRow.description}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Box>
                )}
              </Collapse>
            </Box>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  /**
   * A sort function to put a request with the most recent actions on the top of the list
   */
  function sortRequests(r1: MRRequest, r2: MRRequest): number {
    var date1 = r1.created;
    var date2 = r2.created;

    if (r1.actions && Object.values(r1.actions).length > 0) {
      let actions1 = Object.values(r1.actions);
      date1 = actions1.reduce((a, b) => (a.date > b.date ? a : b)).date;
    }

    if (r2.actions && Object.values(r2.actions).length > 0) {
      let actions2 = Object.values(r2.actions);
      date2 = actions2.reduce((a, b) => (a.date > b.date ? a : b)).date;
    }

    return date2 - date1;
  }
  return (
    <Wrapper>
      <PageTitle title="Manage | In-App Messaging" />
      <HeroContainer>
        <div className="module-container">
          <HeroParagraph>Manage | In-App Messaging</HeroParagraph>
        </div>
      </HeroContainer>

      {user.isSuperAdmin && (
        <RequestsContainer>
          <input
            type="file"
            id="file"
            accept=".xlsx"
            onChange={onFileChange}
            onClick={onInputClick}
            ref={inputFile}
            style={{ display: 'none' }}
          />
          <div>
            <h1>Update Messages:</h1>
            <p>
              <Button
                disabled={processingMessagingReleaseRequest}
                onClick={() => {
                  inputFile!.current!.click();
                }}
                style={{
                  backgroundColor: '#2c58b1',
                  color: 'white',
                }}
              >
                Click here to select and upload the messaging file
              </Button>
            </p>
            <p>
              (accepted file format: <b>.xlsx</b>)
            </p>
          </div>

          {createRequestErrorMessage && (
            <Alert severity="error" onClick={() => setCreateRequestErrorMessage(undefined)}>
              {createRequestErrorMessage}
            </Alert>
          )}
          {(isCreatingRequest || processingMessagingReleaseRequest) && <CircularProgress />}

          <h1>History</h1>
          <TableContainer component={Paper}>
            <Table aria-label="Messaging release requests">
              <TableHead>
                <TableRow>
                  <TableCell>Date/Time</TableCell>
                  <TableCell>Requester</TableCell>
                  <TableCell>Community -&gt; Language</TableCell>
                  <TableCell>Filename</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Logs</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requests
                  ?.sort((r1: MRRequest, r2: MRRequest) => sortRequests(r1, r2))
                  .map((request: MRRequest, index: Number) => (
                    <Row key={`${request.id}-${index}`} row={request} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </RequestsContainer>
      )}
    </Wrapper>
  );
});

export default ManageInAppMessaging;
