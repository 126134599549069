import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ReactQueryConfigProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import theme from './styles/theme';
import GlobalStyles from './styles/GlobalStyles';

import { createStore } from './mobx/createStore';

import Routes from './screens';

import MobxStoreProvider from './components/utils/Mobx/Provider';
import FirebaseMobxProvider from './components/utils/Mobx/FirebaseMobxProvider';

const rootStore = createStore();

Sentry.init({
  dsn: 'https://e1705409855b4d05b01a9561ff5221ba@o530480.ingest.sentry.io/5649971',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const App = () => {
  return (
    <MobxStoreProvider value={rootStore}>
      <ReactQueryConfigProvider config={{ queries: { refetchOnWindowFocus: false } }}>
        <FirebaseMobxProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </ThemeProvider>
        </FirebaseMobxProvider>
      </ReactQueryConfigProvider>
    </MobxStoreProvider>
  );
};

export default App;
