import React from 'react';

import { useFirebaseMobx } from '@src/components/utils/Mobx';

import validateEmail from '@src/utils/validateEmail';

import ModalInput from '@src/components/ModalInput';
import PendingOperationButton from '@src/components/PendingOperationButton';

import Modal from './Modal';

class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { emailPrompt: true, email: '', validEmail: true, isSuccess: false };

    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async onSubmit() {
    if (!validateEmail(this.state.email)) {
      this.setState({ validEmail: false });
      throw new Error();
    }

    try {
      await this.props.store.sendPasswordRecoveryEmail(this.state.email);
      this.setState({ isSuccess: true, emailPrompt: false, email: '', validEmail: true });
    } catch {
      this.setState({ isSuccess: false, emailPrompt: false, email: '', validEmail: true });
    }
  }

  handleChange(event) {
    this.setState({ email: event.target.value });
  }

  onClose() {
    this.setState({ emailPrompt: true, email: '', validEmail: true, isSuccess: false });
    this.props.onToggleModal();
  }

  render() {
    if (this.state.emailPrompt) {
      return (
        <Modal title="Recover Password" isOpen={this.props.isOpen} onRequestClose={this.onClose}>
          <form onSubmit={this.onSubmit} className="modal-form">
            <ModalInput
              label="Email or User Name"
              id="email-or-username"
              required
              value={this.email}
              onChange={this.handleChange}
              validation={!this.state.validEmail}
              validationMessage="Please enter a valid email or user name."
            />

            <PendingOperationButton operation={this.onSubmit} className="save-button">
              Email Recovery Link
            </PendingOperationButton>
            {this.state.isError && <div className="validationResult">Error sending reset email. Please try again.</div>}
          </form>
        </Modal>
      );
    }
    return (
      <Modal
        title="Recover Password"
        hidden={this.props.hidden}
        onRequestClose={this.onClose}
        containerClass="forgot-password-modal-container"
      >
        <p>
          {this.state.isSuccess
            ? ' A password recovery link has been sent to the email address associated with your account. Please click the' +
              ' link in the email to reset your password. '
            : ' Failed to send password recovery link. Please verify that the entered email address is associated with your' +
              ' account and try again. '}
        </p>
      </Modal>
    );
  }
}

export default (props) => {
  const store = useFirebaseMobx();
  return <ForgotPasswordModal store={store} {...props} />;
};
