module.exports = {
  apiKey: 'AIzaSyDSJ8Dj1uNsYXH7hPcf5K0uH09KdiSf56w',
  authDomain: 'wehealth-portal-dev.firebaseapp.com',
  databaseURL: 'https://wehealth-portal-dev.firebaseio.com',
  projectId: 'wehealth-portal-dev',
  storageBucket: 'wehealth-portal-dev.appspot.com',
  messagingSenderId: '285052809412',
  appId: '1:285052809412:web:646cff57814859381dd991',
  measurementId: 'G-LNWZBBLR2W',
};
