import React from 'react';
import styled, { css } from 'styled-components';
import AutoExpandTextarea from 'react-textarea-autosize';

const handleKeyPress = (e, onKeyPress) => {
  if (e.key === 'Enter') {
    e.stopPropagation();
  }
  if (onKeyPress) {
    onKeyPress(e);
  }
};

const textareaStyles = css`
  min-width: 390px;
  min-height: 40px;
  border: 1px solid ${p => p.theme.colors.lightMediumGray};
  border-radius: 3px;
  box-sizing: border-box;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  color: ${p => p.theme.colors.darkGray};
  padding-left: 20px;
  padding: 12px 16px;
  line-height: 24px;
  resize: none;

  &:disabled {
    background-color: ${p => p.theme.colors.lightGray};
    border-color: ${p => p.theme.colors.lightMediumGray};
  }
`;

const StyledAutoExpandTextarea = styled(AutoExpandTextarea)`
  ${textareaStyles}
  resize: none;
`;

const StyledTextArea = styled.textarea`
  ${textareaStyles}
`;

const Textarea = ({ onKeyPress, autoExpand, value, inputRef, ...rest }) => {
  const allProps = {
    ...rest,
    ref: inputRef,
    onKeyPress: e => handleKeyPress(e, onKeyPress),
  };

  if (autoExpand) return <StyledAutoExpandTextarea value={value} {...allProps} />;
  return <StyledTextArea value={value} {...allProps} />;
};

export default Textarea;
