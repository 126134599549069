import { onSnapshot } from 'mobx-state-tree';

import RootStore, { RootStoreModel } from './stores/Root';
import { defaultAuth, defaultOrganization, defaultUser } from './stores';

const defaultStore = {
  auth: defaultAuth,
  organization: defaultOrganization,
  user: defaultUser,
};

let initialStore = defaultStore;

const devEnvironments = ['local', 'development'];

if (devEnvironments.includes(process.env.NODE_ENV as string)) {
  if (sessionStorage.getItem('store')) {
    initialStore = JSON.parse(sessionStorage.getItem('store') as string);
  }
}

export const createStore = (): RootStoreModel => {
  const rootStore = RootStore.create({ ...initialStore });

  if (devEnvironments.includes(process.env.NODE_ENV as string)) {
    onSnapshot(rootStore, snapshot => {
      sessionStorage.setItem('store', JSON.stringify(snapshot));
    });
  }

  return rootStore;
};
