import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import DatePicker from 'react-datepicker';
import { useMutation } from 'react-query';

import * as ROUTES from '@src/constants/routes';

import { post as getVerificationCode } from '@src/apis/verifications';

import {
  getOneHourAheadDisplayString,
  getDefaultTimezoneString,
  localStringToZeroUTCOffsetString,
  toDashSeperatedYYYYMMDDString,
  getFourteenDaysAgoDate,
} from '@src/utils/time';

import { useInject } from '@src/utils/hooks/mobx';
import { useFirebaseMobx } from '@src/components/utils/Mobx';

import Toast from '@src/components/Toast';
import PageTitle from '@src/components/PageTitle';
import PendingOperationButton from '@src/components/PendingOperationButton';
import Countdown from '@src/components/Countdown';

const codePlaceholder = '00000000';

const mapStore = ({ user, organization }) => ({
  user,
  organization,
});

const CodeValidations = observer(() => {
  const { user, organization } = useInject(mapStore);
  const { analytics } = useFirebaseMobx();

  const [testType] = useState('confirmed');
  const [symptomDateYYYYMMDD, setSymptomDateYYYYMMDD] = useState('');
  const [symptomDateObject, setSymptomDateObject] = useState();
  const [dateInvalid, setDateInvalid] = useState(false);
  const [needsReset, setNeedsReset] = useState(false);
  const [code, setCode] = useState(codePlaceholder);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [expirationTime, setExpirationTime] = useState('');
  const [toastInfo, setToastInfo] = useState({
    success: false,
    msg: '',
  });

  const confirmedToast = React.createRef();
  const datePickerEle = React.createRef();
  const codeBoxEle = React.createRef();
  const radioFromEle = React.createRef();

  // Updates the buttonDisabled variable state based on other state variables
  const updateButtonDisabled = () => {
    if (needsReset || dateInvalid) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    updateButtonDisabled();
  });

  const codeTimeStamp = () => {
    setExpirationTime(getOneHourAheadDisplayString());
  };

  const onGetNewVerificationCodeSuccess = React.useCallback(
    (newCode) => {
      setCode(newCode);
      codeTimeStamp();
      setNeedsReset(true);
      updateButtonDisabled();
      analytics.logEvent('verificationCodeGenerated', {
        organizationID: user.organizationID,
        organizationName: organization.name,
      });
    },
    [setCode, codeTimeStamp, setNeedsReset, updateButtonDisabled, analytics]
  );

  const onGetNewVerificationCodeError = React.useCallback(() => {
    setToastInfo({
      success: false,
      msg: 'Could not generate new code, please try again',
    });
    confirmedToast.current.show();
  }, []);

  const [getVerificationCodeMutation] = useMutation(getVerificationCode, {
    onSuccess: onGetNewVerificationCodeSuccess,
    onError: onGetNewVerificationCodeError,
  });

  const getNewVerificationCode = React.useCallback(async () => {
    // map realms to orgs.refID, since refID might be `az` instead of `us-az`
    const _realms = {
      az: 'us-az',
      bm: 'bm',
    };

    await getVerificationCodeMutation({
      codeID: `portal-${new Date().getTime()}`,
      realm: _realms[organization.refID] || null,
      testType,
      testDate:
        symptomDateYYYYMMDD === '' ? symptomDateYYYYMMDD : localStringToZeroUTCOffsetString(symptomDateYYYYMMDD),
    });
  }, [getVerificationCode, testType, symptomDateYYYYMMDD]);

  const handleDate = (date) => {
    if (date) {
      setSymptomDateObject(date);
      const selectedDate = toDashSeperatedYYYYMMDDString(date);
      setDateInvalid(false);
      setSymptomDateYYYYMMDD(selectedDate);
      updateButtonDisabled();
    } else {
      setSymptomDateObject(undefined);
      setSymptomDateYYYYMMDD('');
    }
  };

  const resetState = () => {
    datePickerEle.current.input.value = '';
    radioFromEle.current.reset();
    setCode(codePlaceholder);
    setSymptomDateYYYYMMDD('');
    setSymptomDateObject('');
    setNeedsReset(false);
    setDateInvalid(false);
    updateButtonDisabled();
  };

  const datePicker = (
    <DatePicker
      id="date-picker"
      ref={datePickerEle}
      className={symptomDateYYYYMMDD ? 'with-value' : 'no-value'}
      selected={symptomDateObject}
      minDate={getFourteenDaysAgoDate()}
      maxDate={new Date()}
      onChange={handleDate}
      placeholderText="Choose a date in last 14 days"
    />
  );

  return !user.isSignedIn || user.isFirstTimeUser || (user.passwordResetRequested && user.signedInWithEmailLink) ? (
    <Redirect to={ROUTES.LANDING} />
  ) : (
    <div className="module-container" id="diagnosis-codes">
      <PageTitle title="Diagnosis Verification Codes" />
      <h1>Diagnosis Verification Codes</h1>
      <h2>Submit this form when you are prepared to generate and immediately share the code with a patient.</h2>
      <div className="row" id="test-type-form">
        <div className="col-1">
          <div className="sect-header">COVID-19 Diagnosis</div>
        </div>
        <form id="radio-form" className="col-2" ref={radioFromEle}>
          <div className="radio">
            <input
              defaultChecked
              className="radio-input"
              name="testType"
              type="radio"
              id="confirmed"
              value="confirmed"
            />
            <label htmlFor="confirmed" className="col-2-header-container">
              <div className="col-2-header">Confirmed Positive Test</div>
              <div className="col-2-sub-header">Confirmed positive result from an official testing source.</div>
            </label>
          </div>
        </form>
      </div>

      <div className="row" id="onset-date-form">
        <div className="col-1">
          <div className="sect-header">Symptom Onset Date</div>
          <p>The date must be within the past 14 days</p>
        </div>
        <div className="col-2">
          {datePicker}
          <p className="date-desc">Time zone set to {getDefaultTimezoneString()}</p>
        </div>
      </div>

      <div className="row" id="code-form">
        <div className="col-1">
          <div className="sect-header">Diagnosis Verification Code</div>
          <p>
            Ask the patient to enter this code in the Covid Watch mobile app so that they can anonymously share a
            verified positive diagnosis with others who were nearby when they were possibly contagious.
          </p>
        </div>
        <div className="col-2">
          <PendingOperationButton disabled={buttonDisabled} className="save-button" operation={getNewVerificationCode}>
            Generate Code
          </PendingOperationButton>
          <div
            id="code-box"
            ref={codeBoxEle}
            className={code === codePlaceholder ? 'no-value' : 'with-value code-generated'}
          >
            {code}
          </div>

          {needsReset && (
            <div>
              <Countdown expirationTime={expirationTime} />
              <PendingOperationButton operation={resetState} className="save-button">
                Reset Code and Form
              </PendingOperationButton>
            </div>
          )}
        </div>
      </div>
      <Toast ref={confirmedToast} isSuccess={toastInfo.success} message={toastInfo.msg} />
    </div>
  );
});

export default CodeValidations;
