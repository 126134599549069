import React from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AppSettings, LabelValuePair, toLegacyNextStep, Community } from '@src/lib/types';

import { get as getSettings, post as updateSettings } from '@src/apis/settings';
import { get as getAppData, getIdMapping } from '@src/apis/AppData';

import PageTitle from '@src/components/PageTitle';
import Toast from '@src/components/Toast';

import RiskModel from './components/RiskModel';
import Messaging from './components/Messaging';

import { observer } from 'mobx-react-lite';
import { useInject } from '@src/utils/hooks/mobx';
import { RootStoreModel } from '@src/mobx/stores/Root';
import { FIREBASE_URL, firebaseDbName } from '@src/config/api';

const HeroContainer = styled.div`
  min-height: 248px;
  background-color: ${(p) => p.theme.colors.bluejay};
  display: flex;
  align-items: center;
`;

const HeroParagraph = styled.span`
  color: ${(p) => p.theme.colors.white};
  font-size: 23px;
  line-height: 36px;
`;

const HeroLink = styled.a`
  color: ${(p) => p.theme.colors.white};
  text-decoration: underline;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f4f6f9;
`;

const DEFAULT_ERROR_MESSAGE = '';

const mapStore = ({ organization }: RootStoreModel) => ({
  organization,
});

const AccountBranding = observer(() => {
  const { organization } = useInject(mapStore);

  const errorToastRef = React.useRef<any>(null);
  const [errorMessage, setErrorMessage] = React.useState(DEFAULT_ERROR_MESSAGE);
  const { data: appSettings, isLoading, refetch } = useQuery('appSettings', getSettings);
  const { data: appData } = useQuery('appData', () =>
    getAppData(`${FIREBASE_URL}/${firebaseDbName[organization.refID]}`)
  );
  const { data: idMapping } = useQuery('idMapping', () =>
    getIdMapping(`${FIREBASE_URL}/${firebaseDbName[organization.refID]}`)
  );

  const [appMessaging, setAppMessaging] = React.useState<AppSettings[]>([]);
  const [regionOptions, setRegionOptions] = React.useState<LabelValuePair[]>([]);
  const [selectedRegion, setSelectedRegion] = React.useState<LabelValuePair['value'] | undefined>(undefined);

  const [upadateSettingsMutation, { isLoading: isUpdateLoading }] = useMutation(updateSettings, {
    onSuccess: () => {
      refetch();
    },
    onError: () => setErrorMessage('Something happened. Please try again later'),
  });

  const onRiskModelUpdate = (
    riskModelConfiguration: AppSettings['riskModelConfiguration'],
    onSuccessCallback: () => void
  ) => {
    const newAppSettings = appMessaging?.map((s) => (s.id === selectedRegion ? { ...s, riskModelConfiguration } : s));
    upadateSettingsMutation(newAppSettings, { onSuccess: onSuccessCallback });
  };

  const onMessagingUpdate = (appSts: AppSettings, onSuccessCallback: () => void) => {
    const newAppSettings = appMessaging?.map((s) => (s.id === selectedRegion ? appSts : s));
    upadateSettingsMutation(newAppSettings, { onSuccess: onSuccessCallback });
  };

  const onSelectRegion = (option: LabelValuePair['value']) => setSelectedRegion(option);

  React.useEffect(() => {
    if (!appSettings || !appData || !idMapping) {
      return;
    }

    let newAppMessaging = appSettings?.map(function (region) {
      const communityId = idMapping[region.id] || appData.community[0].id;
      const community: Community = appData.community.find((c) => c.id == communityId) || appData.community[0];

      let messagingId;
      if (community.messagingId == null) {
        messagingId = community.id;
      } else {
        messagingId = community.messagingId;
      }

      let configurationId;
      if (community.configId == null) {
        configurationId = community.id;
      } else {
        configurationId = community.configId;
      }

      const messaging = appData.messaging[messagingId]['en'];
      const config = appData.configuration[configurationId];

      region.vaccinationInfo = messaging?.vaccinationInfo?.map(toLegacyNextStep);
      region.riskModelConfiguration.lowExposureDuration = config.riskModelConfiguration.lowExposureDuration / 60;
      region.riskModelConfiguration.highExposureDuration = config.riskModelConfiguration.highExposureDuration / 60;

      return region;
    });

    setAppMessaging(newAppMessaging);
  }, [appSettings, appData, idMapping]);

  React.useEffect(() => {
    if (appSettings?.length) {
      setRegionOptions(appSettings.map((s) => ({ value: s.id, label: s.name })));
    }
  }, [appSettings, setRegionOptions]);

  React.useEffect(() => {
    if (regionOptions.length) {
      setSelectedRegion(regionOptions[0].value);
    }
  }, [regionOptions, setSelectedRegion]);

  React.useEffect(() => {
    if (errorMessage && errorToastRef.current) {
      errorToastRef.current.show();
    }
  }, [errorMessage]);

  return (
    <Wrapper>
      <PageTitle title="Mobile Application Settings" />
      <HeroContainer>
        <div className="module-container">
          <HeroParagraph>
            The settings on this page are for the mobile application and can only be edited by account Administrators.
            Published changes will directly modify the content in the app for the world to see, so please review all
            changes carefully before publishing changes. For support, connect with our{' '}
            <HeroLink target="_blank" rel="noreferrer" href="https://help.wehealth.org/hc/en-us/requests/new">
              Customer Success Team.
            </HeroLink>
          </HeroParagraph>
        </div>
      </HeroContainer>

      {isLoading ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        appMessaging && (
          <>
            <RiskModel
              appSettings={appMessaging.find((s) => s.id === selectedRegion)}
              onSelectRegion={onSelectRegion}
              selectedRegion={selectedRegion}
              regionOptions={regionOptions}
              onRiskModelUpdate={onRiskModelUpdate}
              isRiskModelUpdateLoading={isUpdateLoading}
            />
            <Messaging
              appSettings={appMessaging.find((s) => s.id === selectedRegion)}
              onMessagingUpdate={onMessagingUpdate}
              isMessagingUpdateLoading={isUpdateLoading}
            />
            <Toast ref={errorToastRef} isSuccess={false} message={errorMessage} />
          </>
        )
      )}
    </Wrapper>
  );
});

export default AccountBranding;
