import React from 'react';
import { observer } from 'mobx-react-lite';

import cwImg from '@src/assets/pw-by-wh-for-vw.svg';

import { useInject } from '@src/utils/hooks/mobx';
import { RootStoreModel } from '@src/mobx/stores/Root';

const mapStore = ({ user, organization }: RootStoreModel) => ({
  user,
  organization,
});

const Footer = observer(() => {
  const { user } = useInject(mapStore);

  return (
    <div id="footer">
      <div className={user.isSignedIn ? 'footerContainer footer-not-login' : 'footerContainer'}>
        <div id="footer-img">
          <img alt="logo" src={cwImg} />
        </div>
        <div className="xs-text" id="footer-text">
          <p id="copyright">© 2020 WeHealth Solutions, PBC. All rights reserved.</p>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1vSPtUEjSYQ0jWxYHlP8OzTzCAWgT9B0E/view?usp=sharing"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a target="_blank" href="https://covidwatch.zendesk.com/hc/en-us" rel="noreferrer">
            Support
          </a>
        </div>
      </div>
    </div>
  );
});

export default Footer;
