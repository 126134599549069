import React from 'react';
import NumberFormat from 'react-number-format';
import { Controller } from 'react-hook-form';

const NumberInput = ({ onChange, ...rest }) => {
  const onValueChange = React.useCallback(({ value }) => {
    onChange(value);
  }, []);

  return <NumberFormat onValueChange={onValueChange} {...rest} />;
};

export default props => (
  <Controller defaultValue="" {...props} render={controllerProps => <NumberInput {...props} {...controllerProps} />} />
);
