import { useContext } from 'react';

import { FirebaseMobxContext } from './FirebaseMobxProvider';

const useFirebaseMobx = () => {
  const store = useContext(FirebaseMobxContext);
  return store;
};

export default useFirebaseMobx;
