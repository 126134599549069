import axiosInstance from '@src/utils/axios';

import { AppSettings } from '@src/lib/types';

import DefaultAPIHandler from './DefaultAPIHandler';

const ai = axiosInstance();

export const get = async (): Promise<AppSettings[]> => {
  try {
    const { data } = await ai.get(`/settings`);
    return data;
  } catch (error) {
    throw DefaultAPIHandler(error, 'Could not retrieve information');
  }
};

export const post = async (payload: AppSettings[]): Promise<AppSettings[]> => {
  try {
    const { data } = await ai.post(`/settings`, payload);
    return data;
  } catch (error) {
    throw DefaultAPIHandler(error, 'Could not update information');
  }
};
