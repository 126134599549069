import React from 'react';
import styled from 'styled-components';
import { FormControlLabel, Collapse, Grid, Tooltip, Checkbox } from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import media from 'css-in-js-media';

import { ChevronDown, ChevronUp } from '@src/assets';

import longArrowRight from '@src/assets/long-arrow-right.svg';
import tooltip from '@src/assets/tooltip.svg';

import Textarea from '@src/components/formFields/Textarea';
import Select from '@src/components/formFields/Select';

import RiskLevelDescriptor, { RISK_LEVEL_IMAGES } from '../../common/RiskLevelDescription';

const CollapseSectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const CollapseSectionHeader = styled.div`
  display: flex;
  padding: 0 25px;
  align-items: center;
  height: 70px;

  justify-content: space-between;
  background-color: ${(p) => p.theme.colors.mediumGray};
  border: 1px solid #dadee3;
`;

const CollapseSectionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;

  svg {
    height: 25px;
    width: 25px;
  }
`;

const CollapseSectionTitle = styled.span`
  font-weight: 600;
  font-size: 23px;
  line-height: 37.21px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${FlexRow}:not(:last-of-type) {
    margin-bottom: 50px;
  }
`;

const MessagingCol = styled.div`
  min-width: 265px;
  width: 100%;
  margin-right: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const SourceTypeCol = styled.div`
  width: 187px;
  width: 100%;
  margin-right: 30px;
  position: relative;
  display: flex;
  flex-direction: column;

  &::before {
    position: absolute;
    bottom: 0px;
    left: 0px;
    transform: translate(-95px, -47px);
    content: url(${longArrowRight});
  }
`;

const SourceCol = styled.div`
  width: 187px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTextArea = styled(Textarea)`
  min-width: auto;
  width: 100%;
  border-width: 1px;
  height: 65px;
  font-size: 14px;
  &::placeholder {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
  }
`;

const DestinationTextArea = styled(StyledTextArea)`
  height: 48px;
  font-size: 14px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  background-position: 90% 50%;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  height: 48px;
`;

const CollapseInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
`;

const Indicator = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  display: inline-block;
`;

const RiskLevelDescriptorWrapper = styled.div`
  margin-right: 30px;
  min-width: 235px;

  @media (min-width: 1280px) {
    min-width: 255px;
  }

  ${media('<=desktop')} {
    display: none;
  }
`;

const StyledLabel = styled.label`
  font-size: 16px;
  margin-bottom: 8px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  span {
    font-size: 11px;
  }
  font-size: 11px;
  @media (min-width: 1280px) {
    span {
      font-size: 13px;
    }
  }
`;

const CharCount = styled.p`
  color: #7b7b7b;
  font-size: 11px;
  margin-top: 10px;
  @media (min-width: 1280px) {
    font-size: 13px;
  }
`;

const StyledGrid = styled(Grid)`
  margin-bottom: 20px;
`;

const TYPE_OPTIONS = [
  { value: 1, label: 'Phone number' },
  { value: 2, label: 'Website' },
  { value: 4, label: 'Informational' },
];

type Props = {
  sectionTitle: string;
  riskType?: string;
  sectionName: string;
  color: string;
};

const FIELD_LABELS_MAP: { [key: string]: string } = {
  0: '1st Priority Recommendation',
  1: '2nd Priority',
  2: '3rd Priority',
  3: '4th Priority',
};

const CollapseSection = ({ sectionTitle, riskType = '', sectionName, color }: Props) => {
  const { register } = useFormContext();
  const { fields } = useFieldArray({ name: sectionName });
  const [collapsedState, setCollapsedState] = React.useState(false);

  const onSetCollapsedState = () => setCollapsedState((s) => !s);

  return (
    <CollapseSectionWrapper>
      <CollapseSectionHeader>
        <CollapseSectionTitle>
          <Indicator color={color} /> {sectionTitle}
        </CollapseSectionTitle>
        <CollapseSectionButton type="button" onClick={onSetCollapsedState}>
          {collapsedState ? <ChevronUp /> : <ChevronDown />}
        </CollapseSectionButton>
      </CollapseSectionHeader>
      <Collapse in={collapsedState}>
        <CollapseInnerContainer>
          <Grid container spacing={1}>
            <Grid container item alignItems="flex-start" xs={3}>
              <RiskLevelDescriptorWrapper>
                <RiskLevelDescriptor riskType={riskType} />
              </RiskLevelDescriptorWrapper>
            </Grid>
            <Grid container item xs={9}>
              <FormContainer>
                {fields?.map((field, idx) => (
                  <StyledGrid container key={field.id}>
                    <Grid container item xs={6}>
                      <MessagingCol>
                        <Grid justify="space-between" container>
                          <Grid item>
                            <StyledLabel>{FIELD_LABELS_MAP[idx]}</StyledLabel>
                          </Grid>
                          <Grid item>
                            <Tooltip
                              title="This next step recommendation will appear on the mobile app home screen and lets app users know what action to take."
                              placement="top"
                            >
                              <img src={tooltip} alt="Help" />
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <StyledTextArea
                          label="Label"
                          inputRef={register()}
                          index={idx}
                          name={`${sectionName}[${idx}].description`}
                          placeholder="Example: Learn how to protect myself and others."
                          defaultValue={field.description}
                        />
                      </MessagingCol>
                    </Grid>
                    <Grid container item xs={3}>
                      <SourceTypeCol>
                        <Grid justify="space-between" container>
                          <Grid item>
                            <StyledLabel>Action</StyledLabel>
                          </Grid>
                          <Grid item>
                            <Tooltip
                              title="When a mobile app user taps on a recommendation, the tap action will either do nothing (informational only), take them to a website, or open a prompt to call a phone #."
                              placement="top"
                            >
                              <img src={tooltip} alt="Help" />
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <StyledSelect
                          selectRef={register()}
                          name={`${sectionName}[${idx}].type`}
                          placeholder="Select Type"
                          options={TYPE_OPTIONS}
                          defaultValue={field.type}
                        />
                      </SourceTypeCol>
                    </Grid>
                    <Grid container item xs={3}>
                      <SourceCol>
                        <Grid justify="space-between" container>
                          <Grid item>
                            <StyledLabel>Destination</StyledLabel>
                          </Grid>
                          <Grid item>
                            <Tooltip
                              title="When a mobile app user taps on a recommendation, the tap action will either do nothing (informational only), take them to a website, or open a prompt to call a phone #."
                              placement="top"
                            >
                              <img src={tooltip} alt="Help" />
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <DestinationTextArea
                          inputRef={register()}
                          name={`${sectionName}[${idx}].url`}
                          placeholder="Web address, phone #, etc."
                          defaultValue={field.url}
                        />
                      </SourceCol>
                    </Grid>
                  </StyledGrid>
                ))}
              </FormContainer>
            </Grid>
          </Grid>
        </CollapseInnerContainer>
      </Collapse>
    </CollapseSectionWrapper>
  );
};

export default CollapseSection;
