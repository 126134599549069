import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import MainLayout from '@src/components/layouts/MainLayout';

type Props = {} & RouteProps;

const PublicRouter = observer(({ component, ...rest }: Props) => {
  if (!component) return null;

  const Component = component;

  return (
    <Route
      {...rest}
      component={component}
      render={(routerProps) => (
        <MainLayout>
          <Component {...routerProps} />
        </MainLayout>
      )}
    />
  );
});

export default PublicRouter;
