module.exports = {
  apiKey: 'AIzaSyCky9W0NEVhIsiSGZuMeAuUQzENwrRiYBE',
  authDomain: 'wehealth-portal.firebaseapp.com',
  databaseURL: 'https://wehealth-portal.firebaseio.com',
  projectId: 'wehealth-portal',
  storageBucket: 'wehealth-portal.appspot.com',
  messagingSenderId: '638378200276',
  appId: '1:638378200276:web:ffff637ddb1c414733bf41',
  measurementId: 'G-5ENSLJJBG9',
};
