import { NextStepsTypes } from '@src/lib/types';

export type Community = {
  id: string;
  name: string;
  configId?: string;
  messagingId?: string;
};

export type RiskModelConfiguration = {
  attenuationBuckets: number[];
  attenuationWeights: number[];
  excludeDaysRelativeToPossibleInfectedDay: number[];
  highExposureDuration: number;
  infectiousnessWeights: number[];
  keysAfterSymptomsOnset: number;
  keysAfterTestDate: number;
  keysBeforeSymptomsOnset: number;
  keysBeforeTestDate: number;
  lowExposureDuration: number;
  reportTypeWeights: number[];
  riskLevelsForDaysRelativeToSymptomsStartDay: Map<string, number>;
  riskLevelsForDaysRelativeToTestDay: Map<string, number>;
};
export type ServerConfiguration = {
  tekDownloadBaseUrl: string;
  tekDownloadIndexUrl: string;
  tekUploadUrl: string;
  verificationApiKey: string;
  verificationUrl: string;
  whApiUrl: string;
};

export interface DiagnosisKeysDataMapping {
  daysSinceOnsetToInfectiousness: Map<string, number>;
  defaultReportType: number;
  infectiousnessWithoutOnset: number;
}

export type Configuration = {
  exposurePeriodDays: number;
  sendExposureNotificationsEndTime: string;
  sendExposureNotificationsStartTime: string;

  riskModelConfiguration: RiskModelConfiguration;
  serverConfiguration: ServerConfiguration;
  diagnosisKeysDataMapping: DiagnosisKeysDataMapping;
};

export type NextStep = {
  type: string;
  url: string;
  description: string;
};

export function toLegacyNextStep(nextStep: NextStep): NextStepsTypes {
  var type = 0;
  switch (nextStep.type) {
    case 'WEBSITE': {
      type = 2;
      break;
    }
    case 'PHONE': {
      type = 1;
      break;
    }
    case 'INFO': {
      type = 4;
      break;
    }
  }
  return {
    type: type,
    url: nextStep.url,
    description: nextStep.description,
  };
}

export type NextSteps = {
  appState: string[][];
  messages: NextStep[];
};

export type Messaging = {
  nextSteps: NextSteps[];
  vaccinationInfo: NextStep[];
  verificationCodeInfo: NextStep[];
};

type LanguageToMessaging = {
  [language: string]: Messaging;
};

type IdToLanguage = {
  [id: string]: LanguageToMessaging;
};

type IdToConfiguration = {
  [id: string]: Configuration;
};

export type AppData = {
  community: Community[];
  configuration: IdToConfiguration;
  messaging: IdToLanguage;
};
