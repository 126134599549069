import React from 'react';
import styled from 'styled-components';

import Modal from '@src/components/modals/Modal';
import Checkbox from '@src/components/formFields/Checkbox';
import Button from '@src/components/buttons/Button';

const SaveConfirmationTitle = styled.h1`
  font-size: 22px;
  margin: 0 0 30px 0;
`;

const SaveConfirmationDescription = styled.span`
  line-height: 24px;
`;

const CheckboxLabelGroup = styled.div`
  display: inline-flex;
  margin: 30px 0;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 15px;
`;

const StyledLabel = styled.label`
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SaveConfirmation = ({ isOpen, onToggleModal, onResetForm }) => {
  const onDiscardChanges = React.useCallback(() => {
    onResetForm();
    onToggleModal();
  }, []);
  return (
    <Modal isOpen={isOpen} onRequestClose={onToggleModal}>
      <div>
        <SaveConfirmationTitle>Cancel and Discard Changes</SaveConfirmationTitle>
        <SaveConfirmationDescription>
          Are you sure that you want to cancel and discard all new changes? The previous state will be preserved and
          unchanged and only new changes will be discarded. This action cannot be undone.
        </SaveConfirmationDescription>
        <CheckboxLabelGroup>
          <StyledCheckbox id="accept" name="accept" />
          <StyledLabel htmlFor="accept">Yes, I want to discard all new changes.</StyledLabel>
        </CheckboxLabelGroup>
        <ButtonGroup>
          <Button onClick={onToggleModal} variant="tertiary">
            Go Back
          </Button>
          <Button onClick={onDiscardChanges} variant="primary">
            Discard Changes
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

export default SaveConfirmation;
