import React from 'react';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Firebase from 'firebase';

import * as ROUTES from '@src/constants/routes';

import { useInject } from '@src/utils/hooks/mobx';
import { RootStoreModel } from '@src/mobx/stores/Root';

import TransitionContainer from '@src/components/navigation/TransitionContainer';
import PublicRoute from '@src/components/navigation/PublicRoute';
import PrivateRoute from '@src/components/navigation/PrivateRoute';

import Login from '@src/screens/Login';
import CodeValidations from '@src/screens/CodeValidations';
import Settings from '@src/screens/Settings';
import ManageTeams from '@src/screens/ManageTeams';
import AccountBranding from '@src/screens/AccountBranding';
import ManageInAppMessaging from '@src/screens/ManageInAppMessaging';
import NotFound from '@src/screens/404';
import Dashboard from './Dashboard';

const mapStore = ({ user }: RootStoreModel) => ({
  user,
});

const App = observer(() => {
  const { user } = useInject(mapStore);
  const [isLoading, setIsLoading] = React.useState(true);
  const { push: navigate } = useHistory();

  React.useEffect(() => {
    const authListener = Firebase.auth().onAuthStateChanged((u) => {
      setIsLoading(true);
      if (u) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        navigate('/');
      }
    });

    return () => {
      authListener();
    };
  }, [navigate]);

  return (
    <TransitionContainer isLoading={isLoading || user.isLoading}>
      <Switch>
        <PublicRoute path={ROUTES.LANDING} exact component={Login} />
        <PrivateRoute path={ROUTES.DASHBOARD + ROUTES.DASHBOARD_PARAMS} component={Dashboard} />
        <PrivateRoute path={ROUTES.CODE_VALIDATIONS} component={CodeValidations} />
        <PrivateRoute path={ROUTES.SETTINGS} component={Settings} />
        <PrivateRoute path={ROUTES.MANAGE_MEMBERS} component={ManageTeams} />
        <PrivateRoute path={ROUTES.BRANDING} component={AccountBranding} />
        <PrivateRoute path={ROUTES.MANAGE_IN_APP_MESSAGING} component={ManageInAppMessaging} />

        <Route path={ROUTES.NOT_FOUND} component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </TransitionContainer>
  );
});

export default App;
