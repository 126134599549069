import { useStore } from '@src/components/utils/Mobx';
import { RootStoreModel } from '@src/mobx/stores/Root';

export type MapStore<T> = (store: RootStoreModel) => T;

const useInject = <T>(mapStore: MapStore<T>) => {
  const store = useStore();
  return mapStore(store);
};

export default useInject;
