import React from 'react';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import mapValues from 'lodash/mapValues';
import isString from 'lodash/isString';

import { AppSettings, NextStepsTypes } from '@src/lib/types';

import Button from '@src/components/buttons/Button';

import SaveConfirmationModal from '../../modals/SaveConfirmation';
import CancelConfirmation from '../../modals/CancelConfirmation';

import CollapseSection from './components/CollapseSection';

const MessagingContainer = styled.div`
  background-color: ${(p) => p.theme.colors.white};
`;

const MessagingHeaderContainer = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-start;
  }
`;

const InnerContainer = styled.div`
  padding: 100px 0;
`;

const MessagingHeader = styled.h1`
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 700;
`;

const MessagingDescription = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

const MessageSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const FormButtonGroup = styled.div`
  button:first-of-type {
    margin-right: 30px;
  }
`;

const HR = styled.hr`
  border: 1px solid #bdbdbd;
  width: 100%;
  margin: 80px 0 100px 0;
`;

const DEFAULT_MODAL_STATE = {
  type: 'save', // type: 'save' | 'cancel'
  isOpen: false,
};

type Props = {
  appSettings?: AppSettings;
  onMessagingUpdate: (appSettings: AppSettings, onSuccessCallback: () => void) => void;
  isMessagingUpdateLoading: boolean;
};

const Messaging = ({ appSettings, onMessagingUpdate, isMessagingUpdateLoading }: Props) => {
  const [modalState, setModalState] = React.useState(DEFAULT_MODAL_STATE);
  const methods = useForm<AppSettings>();

  const { reset, handleSubmit } = methods;

  React.useEffect(() => {
    if (appSettings) {
      reset(appSettings);
    }
  }, [appSettings]);

  const onResetForm = () => reset(appSettings);
  const onToggleModal = () => setModalState((s) => ({ ...s, isOpen: !s.isOpen }));
  const onOpenConfirmationModal = () => setModalState({ type: 'save', isOpen: true });
  const onOpenCancelModal = () => setModalState({ type: 'cancel', isOpen: true });

  const onSubmitForm = React.useCallback(async () => {
    await handleSubmit((d) => {
      if (appSettings) {
        const newMessaging = mapValues(d, (v: NextStepsTypes[]) =>
          v.map((e) => (isString(e.type) ? { ...e, type: Number(e.type) } : e))
        );
        const newAppSettings = { ...appSettings, ...newMessaging };
        onMessagingUpdate(newAppSettings, onToggleModal);
      }
    })();
  }, [appSettings, onToggleModal]);

  return (
    <FormProvider {...methods}>
      <MessagingContainer>
        <InnerContainer className="module-container">
          <MessagingHeaderContainer>
            <MessagingHeader>Messaging</MessagingHeader>
            <MessagingDescription>
              Each mobile app user is assigned one of four risk levels based on their possible exposures to other
              anonymous app users who are positively diagnosed with COVID-19. Each risk level is paired with “next step”
              recommendations that can be customized below.
            </MessagingDescription>
            {/* <Button variant="primary">Add Spanish Option</Button> */}
          </MessagingHeaderContainer>
          <MessageSectionsWrapper>
            <CollapseSection
              color="#219754"
              riskType="none"
              sectionName="nextStepsNoSignificantExposure"
              sectionTitle="No Exposure Detected"
            />

            <CollapseSection
              riskType="low"
              color="#FF7700"
              sectionName="nextStepsLowExposure"
              sectionTitle="Low Exposure"
            />

            <CollapseSection
              sectionName="nextStepsSignificantExposure"
              riskType="high"
              color="#F05451"
              sectionTitle="High Exposure"
            />

            <CollapseSection
              sectionName="nextStepsVerifiedPositive"
              riskType="verified"
              color="#F05451"
              sectionTitle="Verified Positive Diagnosis"
            />

            <HR />

            <CollapseSection
              sectionName="nextStepsVerificationCode"
              riskType="code"
              color="transparent"
              sectionTitle="Where is my code?"
            />

            <CollapseSection
              sectionName="vaccinationInfo"
              riskType="vaccine"
              color="transparent"
              sectionTitle="Where is my vaccine?"
            />
          </MessageSectionsWrapper>
        </InnerContainer>
      </MessagingContainer>
      <SaveConfirmationModal
        isOpen={modalState.type === 'save' && modalState.isOpen}
        onToggleModal={onToggleModal}
        onSubmitForm={onSubmitForm}
        isLoading={isMessagingUpdateLoading}
      />
      <CancelConfirmation
        isOpen={modalState.type === 'cancel' && modalState.isOpen}
        onToggleModal={onToggleModal}
        onResetForm={onResetForm}
      />
    </FormProvider>
  );
};

export default Messaging;
