import { ApiErrorResponse } from '@src/lib/types';

import APIError from './APIError';

const DefaultAPIErrorHandler = (
  error: ApiErrorResponse | Error | unknown, // If the error happens during the API call, we'll get an ApiErrorResponse, if it happens in code, we'll get a generic Error
  defaultErrorMessage = 'There was an error.'
) => {
  if ((error as ApiErrorResponse).response) throw error; // Generic Error, so no processing is necessary

  const { response } = error as ApiErrorResponse;

  const message = response.message || defaultErrorMessage;

  throw new APIError(message, response.status); // we throw the Error so that a duck can catch it and handle it
};

export default DefaultAPIErrorHandler;
