import axiosInstance, { axiosErrorHandler, createBaseRequestInterceptor } from '@src/utils/axios';
import DefaultAPIHandler from './DefaultAPIHandler';
import { MRRequest } from '@src/lib/types';
import { allowedOrigins } from '@src/config/api';

const axios = axiosInstance();

const baseHeaders = {
  'Access-Control-Allow-Origin': allowedOrigins,
  Vary: 'Origin',
};

export const create = async (payload: any): Promise<MRRequest> => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...baseHeaders,
      },
    };
    let fd = new FormData();
    fd.append('file', payload);
    let endpoint = `messaging-release/requests/create`;
    const { data } = await axios.post(endpoint, fd, config);
    return data;
  } catch (error) {
    throw DefaultAPIHandler(error, 'Could not update information');
  }
};

export const publish = async (requestId: string, env: string): Promise<any> => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...baseHeaders,
      },
    };
    const params = new URLSearchParams();
    params.append('requestId', requestId);
    params.append('env', env);

    let endpoint = `messaging-release/requests/publish`;
    const { data } = await axios.post(endpoint, params, config);
    return data;
  } catch (error) {
    throw DefaultAPIHandler(error, 'Could not update information');
  }
};

export const revert = async (requestId: string, env: string): Promise<any> => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...baseHeaders,
      },
    };
    const params = new URLSearchParams();
    params.append('requestId', requestId);
    params.append('env', env);

    let endpoint = `messaging-release/requests/revert`;
    const { data } = await axios.post(endpoint, params, config);
    return data;
  } catch (error) {
    throw DefaultAPIHandler(error, 'Could not update information');
  }
};

export const request = async (requestId: string): Promise<MRRequest> => {
  try {
    let endpoint = `messaging-release/request`;
    const { data } = await axios.get(endpoint, {
      params: { requestId: requestId },
      headers: baseHeaders,
    });
    return data;
  } catch (error) {
    throw DefaultAPIHandler(error, 'Could not update information');
  }
};

export const requests = async (): Promise<MRRequest[]> => {
  try {
    let endpoint = `messaging-release/requests`;
    const { data } = await axios.get(endpoint, {
      headers: baseHeaders,
    });
    return data;
  } catch (error) {
    throw DefaultAPIHandler(error, 'Could not update information');
  }
};
