import media from 'css-in-js-media';
import { createGlobalStyle } from 'styled-components';
// import { normalize } from 'styled-normalize';

import theme from './theme';

export default createGlobalStyle`
html,
body,
#app,
#app > div,
#root {
  display: flex;
  flex-direction: column;
  min-height: 99vh;
  // Default text style
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: ${theme.colors.darkGray};
  margin: 0;
}
.hidden {
  display: none;
}

h1 {
  ${media('>phone')} {
    margin-top: 52px;
    font-size: 32px;
    line-height: 40px;
  }

  ${media('<=phone')} {
    margin-top: 26px;
    font-size: 24px; // 0.75 * >phone
    line-height: 30px; // 0.75 * >phone
    text-align: center;
  }
  font-weight: 600;
}

h2 {
  ${media('>phone')} {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
  }
  ${media('<=phone')} {
    font-weight: 600;
    font-size: 21px; // 0.75 * >phone
    line-height: 26px; // 0.75 * >phone
  }
}

h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}

input {
  &[type="text"], &[type="email"], &[type="password"], &[type="tel"], &[type="time"], &[type="number"] {
    width: 100%;
    height: 40px;
    border: 1px solid ${theme.colors.lightMediumGray};
    border-radius: 4px;
    box-sizing: border-box;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
    color: ${theme.colors.darkGray};
    padding: 0 7px 0 20px;

    &:disabled {
      background-color: ${theme.colors.lightGray};
    }
  }

}

select {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  color: ${theme.colors.darkGray};
}

label {
  font-size: 18px;
  font-weight: 500;
}

a {
  color: ${theme.colors.bluejay};
  text-decoration: none;
}

.small-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.xs-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.module-container {

  ${media('>phone')}  {
    margin: 0 98px;
    // padding-right: 101px;
  }
  ${media('<=phone')} {
    margin: 0 5px;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.module-container-login {
  padding-right: 0px;
  ${media('<=phone')} {
    margin-bottom: 26px;
  }
}
`;
