/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import * as ROUTES from '@src/constants/routes';

import addMember from '@src/assets/add-member.svg';
import arrowLeft from '@src/assets/arrow-left.svg';
import arrowRight from '@src/assets/arrow-right.svg';

import { useFirebaseMobx } from '@src/components/utils/Mobx';
import { PAGE_SIZE } from '@src/mobx/stores';

import Toast from '@src/components/Toast';
import RoleSelector from '@src/components/RoleSelector';
import PageTitle from '@src/components/PageTitle';

import AddMemberModal from '@src/components/modals/AddMemberModal';
import ChangeRoleModal from '@src/components/modals/ChangeRoleModal';
import ChangeStatusModal from '@src/components/modals/ChangeStatusModal';

import '@src/styles/screens/manage_teams.scss'; // NOTE: see note in index.scss

const ManageTeams = observer(() => {
  const store = useFirebaseMobx();
  const userEmail = store.data.user.email;

  const [toastMessage, setToastMessage] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const confirmationToast = useRef();

  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [modalUserProperties, setModalUserProperties] = useState({});

  const onAddMemberCancel = () => {
    setShowAddMemberModal(false);
  };

  const onAddMemberSuccess = () => {
    setToastMessage('Success: member email invitation sent');
    setIsSuccess(true);
    setShowAddMemberModal(false);
    confirmationToast.current.show();
  };

  const onAddMemberFailure = (e) => {
    console.log(e);
    if (e.code === 'already-exists') {
      setToastMessage('The email address is already in use by another account');
    } else {
      setToastMessage('Member email invitation failed to send');
    }
    setIsSuccess(false);
    confirmationToast.current.show();
    setShowAddMemberModal(false);
  };

  const handleRoleChange = (isAdmin, firstName, lastName, email) => {
    setModalUserProperties({
      isAdmin,
      firstName,
      lastName,
      email,
    });
    setShowChangeRoleModal(true);
  };

  const onChangeRoleModalClose = () => {
    setShowChangeRoleModal(false);
  };

  const handleStatusChange = (email, toStatus, firstName, lastName) => {
    setModalUserProperties({
      email,
      toStatus,
      firstName,
      lastName,
    });
    setShowChangeStatusModal(true);
  };

  const onChangeStatusModalClose = () => {
    setShowChangeStatusModal(false);
  };

  const resetPassword = async (e, email) => {
    e.preventDefault();
    try {
      await store.sendPasswordRecoveryEmail(email);
      setToastMessage(`Password reset email sent to ${email}`);
      setIsSuccess(true);
      confirmationToast.current && confirmationToast.current.show();
    } catch (err) {
      console.log(err);
      setToastMessage('Password reset failed. Please try again');
      setIsSuccess(false);
      confirmationToast.current.show();
    }
  };

  return !store.data.user.isSignedIn ||
    !store.data.user.isAdmin ||
    store.data.user.isFirstTimeUser ||
    (store.data.user.passwordResetRequested && store.data.user.signedInWithEmailLink) ? (
    <Redirect to={ROUTES.LANDING} />
  ) : (
    <div className="module-container">
      <PageTitle title="Manage Members" />
      <h1>Manage Members</h1>
      <button
        type="button"
        className="button btn-medium btn-tertiary add-member-button"
        onClick={() => setShowAddMemberModal(true)}
      >
        <img src={addMember} alt="" />
        <span className="add-button-text">Add Member</span>
      </button>
      <p className="mobile-msg">Use desktop to view members.</p>
      <AddMemberModal
        isOpen={showAddMemberModal}
        onClose={onAddMemberCancel}
        onSuccess={onAddMemberSuccess}
        onFailure={onAddMemberFailure}
      />
      <ChangeRoleModal
        isOpen={showChangeRoleModal}
        onRequestClose={onChangeRoleModalClose}
        userProperties={modalUserProperties}
      />
      <ChangeStatusModal
        isOpen={showChangeStatusModal}
        onRequestClose={onChangeStatusModalClose}
        userProperties={modalUserProperties}
      />
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email Address</th>
            <th>Role</th>
            <th>Status</th>
            <th>Settings</th>
          </tr>
        </thead>
        <tbody>
          {store.data.organization.members &&
            store.data.organization.currentPageOfMembers.map((data, index) => (
              <tr key={index}>
                <td>{`${data.lastName}, ${data.firstName}`}</td>
                <td>{data.email}</td>
                <td style={{ padding: 0 }}>
                  <RoleSelector
                    memberIndex={index + (store.data.organization.membersPage - 1) * PAGE_SIZE}
                    onChange={() => handleRoleChange(data.isAdmin, data.firstName, data.lastName, data.email)}
                  />
                </td>
                <td style={{ padding: 0 }}>
                  <div className="custom-select">
                    <select
                      disabled={data.email === userEmail}
                      className={!data.disabled ? 'active' : 'inactive'}
                      value={!data.disabled ? 'active' : 'deactivated'}
                      onChange={(e) => handleStatusChange(data.email, e.target.value, data.firstName, data.lastName)}
                    >
                      <option value="active">Active</option>
                      <option value="deactivated">Deactivated</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div className="xs-text settings-container">
                    <a onClick={(e) => resetPassword(e, data.email)}>Reset Password</a>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="table-bottom-container">
        <div className="pages-container">
          <div
            className="arrow"
            onClick={(e) => {
              e.preventDefault();
              if (store.data.organization.membersPage - 1 > 0) {
                store.data.organization.setMembersPage(store.data.organization.membersPage - 1);
              }
            }}
          >
            <img style={{ marginRight: 4 }} src={arrowLeft} alt="Previous" />
          </div>
          {[...Array(store.data.organization.totalPagesOfMembers).keys()].map((pageNumber) => (
            <div
              className={pageNumber + 1 === store.data.organization.membersPage ? 'current-page' : 'page'}
              key={pageNumber.toString()}
              onClick={() => {
                store.data.organization.setMembersPage(pageNumber + 1);
              }}
            >
              {pageNumber + 1}
            </div>
          ))}
          <div
            className="arrow"
            onClick={(e) => {
              e.preventDefault();
              if (store.data.organization.membersPage + 1 <= store.data.organization.totalPagesOfMembers) {
                store.data.organization.setMembersPage(store.data.organization.membersPage + 1);
              }
            }}
          >
            <img style={{ marginLeft: 4 }} src={arrowRight} alt="Next" />
          </div>
        </div>
      </div>
      <Toast ref={confirmationToast} isSuccess={isSuccess} message={toastMessage} />
    </div>
  );
});

export default ManageTeams;
