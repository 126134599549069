import React from 'react';
import styled from 'styled-components';

import Transition from 'react-transition-group/Transition';

import loader from '@src/assets/animated-loader-blue.svg';

type Props = {
  isLoading?: boolean;
};

const Content = styled.div<{ transitionState: string }>`
  background: white;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  &::before,
  &::after {
    position: absolute;
    content: ' ';
  }

  &::before {
    background: white;
    z-index: 40;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: ${(props) => (props.transitionState === 'exiting' ? 0 : 1)};
    transition: all 0.5s;
    display: ${(props) => (props.transitionState === 'exited' ? 'none' : 'block')};
  }

  &::after {
    background: transparent;
    z-index: 50;
    display: ${(props) => (props.transitionState === 'exited' ? 'none' : 'inline-block')};
    background-image: url(${loader});
    opacity: ${(props) => (props.transitionState === 'exiting' ? 0 : 1)};
    background-size: 100px 100px;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin-top: ${(100 / 2) * -1}px;
    margin-left: ${(100 / 2) * -1}px;
    transition: all 0.5s;
  }
`;

const TransitionPage: React.FC<Props> = ({ children, isLoading }) => (
  <Transition in={isLoading} timeout={500}>
    {(transitionState) => <Content transitionState={transitionState}>{children}</Content>}
  </Transition>
);

export default TransitionPage;
