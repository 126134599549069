import React from 'react';
import styled from 'styled-components';

import Modal from '@src/components/modals/Modal';
import Checkbox from '@src/components/formFields/Checkbox';
import Button from '@src/components/buttons/Button';

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  onToggleModal: () => void;
  onSubmitForm: () => void;
};

const SaveConfirmationTitle = styled.h1`
  font-size: 22px;
  margin: 0 0 30px 0;
`;

const SaveConfirmationDescription = styled.span`
  line-height: 24px;
`;

const CheckboxLabelGroup = styled.div`
  display: inline-flex;
  margin: 30px 0;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 15px;
`;

const StyledLabel = styled.label`
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  button:not(:last-of-type) {
    margin-right: 25px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const SaveConfirmation = ({ isOpen, onToggleModal, onSubmitForm, isLoading }: Props) => {
  return (
    <Modal isOpen={isOpen} shouldCloseOnOverlayClick={!isLoading} onRequestClose={onToggleModal}>
      <div>
        <SaveConfirmationTitle>Save and Submit to App</SaveConfirmationTitle>
        <SaveConfirmationDescription>
          Are you sure that you want to save and submit all new changes to the app?Please review all changes carefully.
          This action will directly modify the the content of the mobile app and will go live for all app users to see
          within [TIME FRAME].
        </SaveConfirmationDescription>
        <CheckboxLabelGroup>
          <StyledCheckbox id="accept" name="accept" />
          <StyledLabel htmlFor="accept">Yes, I want to save and submit the changes.</StyledLabel>
        </CheckboxLabelGroup>
        <ButtonGroup>
          <StyledButton onClick={onToggleModal} variant="tertiary">
            Cancel
          </StyledButton>
          <StyledButton isLoading={isLoading} onClick={onSubmitForm} variant="primary">
            Submit
          </StyledButton>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

export default SaveConfirmation;
