import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Grid, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Redirect, useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useQuery, QueryConfig } from 'react-query';
import * as _ from 'lodash';
import moment from 'moment';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';

import * as ROUTES from '@src/constants/routes';
import {
  getAppAnalytics,
  getBitlyClicks,
  getExposureNotifications,
  getVerificationCodes,
  getPositiveCases,
  getActiveUsers,
  getAppDownloads,
} from '@src/apis/analytics';

import { useInject } from '@src/utils/hooks/mobx';

import PageTitle from '@src/components/PageTitle';

const organizationName = {
  arizona: 'Arizona',
};

const useQuerySettings: QueryConfig<any, any> = {
  cacheTime: 30 * 60 * 1000, // 30 minutes
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

const CommunitySelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 15px 15px 0;
`;
const CommunitySelectWrapper = styled.div``;
const CommunitySelectTitle = styled.div``;
const CommunitySelect = styled.select``;
const CommunityOption = styled.option``;

const ChartHeading = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
`;

const ChartSubHeading = styled.p`
  font-weight: 100;
  font-size: 14px;
  margin-left: 20px;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  padding: 10px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
const CustomTooltipWrapper = styled.div`
  background-color: white;
  padding: 10px;
  border: 1px solid lightgray;
  z-index: 100;
`;

const communityMap: any = {
  Arizona: {
    'us-az': {
      id: 'us-az',
      name: 'State of Arizona',
      bitlyTag: 'AZ_ASU',
    },
    'us-az-academy-village': {
      id: 'us-az-academy-village',
      name: 'Academy Village',
      bitlyTag: 'AZ_AcademyVillage',
    },
    'us-az-apache': {
      id: 'us-az-apache',
      name: 'Apache County',
      bitlyTag: 'AZ_Apache',
    },
    'us-az-asu': {
      id: 'us-az-asu',
      name: 'Arizona State University',
      bitlyTag: 'AZ_State',
    },
    'us-az-cochise': {
      id: 'us-az-cochise',
      name: 'Cochise County',
      bitlyTag: 'AZ_Cochise',
    },
    'us-az-coconino': {
      id: 'us-az-coconino',
      name: 'Coconino County',
      bitlyTag: 'AZ_Coconino',
    },
    'us-az-fair': {
      id: 'us-az-fair',
      name: 'Fort Apache Indian Reservation',
      bitlyTag: 'AZ_FAIR',
    },
    'us-az-gila': {
      id: 'us-az-gila',
      name: 'Gila County',
      bitlyTag: 'AZ_Gila',
    },
    'us-az-graham': {
      id: 'us-az-graham',
      name: 'Graham County',
      bitlyTag: 'AZ_Graham',
    },
    'us-az-greenlee': {
      id: 'us-az-greenlee',
      name: 'Greenlee County',
      bitlyTag: 'AZ_Greenlee',
    },
    'us-az-la-paz': {
      id: 'us-az-la-paz',
      name: 'La Paz County',
      bitlyTag: 'AZ_LaPaz',
    },
    'us-az-maricopa': {
      id: 'us-az-maricopa',
      name: 'Maricopa County',
      bitlyTag: 'AZ_Maricopa',
    },
    'us-az-mohave': {
      id: 'us-az-mohave',
      name: 'Mohave County',
      bitlyTag: 'AZ_Mohave',
    },
    'us-az-nau': {
      id: 'us-az-nau',
      name: 'Northern Arizona University',
      bitlyTag: 'AZ_NAU',
    },
    'us-az-navajo': {
      id: 'us-az-navajo',
      name: 'Navajo County',
      bitlyTag: 'AZ_Navajo',
    },
    'us-az-pima': {
      id: 'us-az-pima',
      name: 'Pima County',
      bitlyTag: 'AZ_Pima',
    },
    'us-az-pinal': {
      id: 'us-az-pinal',
      name: 'Pinal County',
      bitlyTag: 'AZ_Pinal',
    },
    'us-az-santa-cruz': {
      id: 'us-az-santa-cruz',
      name: 'Santa Cruz County',
      bitlyTag: 'AZ_SantaCruz',
    },
    'us-az-uoa': {
      id: 'us-az-uoa',
      name: 'University of Arizona',
      bitlyTag: 'AZ_UofA',
    },
    'us-az-yavapai': {
      id: 'us-az-yavapai',
      name: 'Yavapai County',
      bitlyTag: 'AZ_Yavapai',
    },
    'us-az-yuma': {
      id: 'us-az-yuma',
      name: 'Yuma County',
      bitlyTag: 'AZ_Yuma',
    },
  },
  Bermuda: {
    bm: {
      id: 'bm',
      name: 'Residents',
      bitlyTag: 'BM_Res',
    },
    'bm-tourists': {
      id: 'bm-tourists',
      name: 'Visitors',
      bitlyTag: 'BM_Vis',
    },
  },
};

const fillZeroItems = (data: any, itemClosure: Function) => {
  let time = moment();
  for (let days = 1; days <= 90; days += 1) {
    if (days > data.length) {
      data.unshift(itemClosure(time));
      continue;
    }

    const actualDate = data[data.length - days].date;
    const expectedDate = time.format('l');

    if (actualDate !== expectedDate) {
      // Covers the case when the data is in the "future" compared to the local time.
      // Hapens when it's a next day in UTC but the local time is still current day.
      if (moment(actualDate).isBefore(moment(expectedDate))) {
        data.splice(data.length - (days - 1), 0, itemClosure(time));
      }
    }

    time = time.subtract(1, 'days');
  }
};

const formatVerificationCodes = (data: any): any[] => {
  // Format data
  const groupedByDate: any = _.groupBy(data, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      tempObj[`${item.app} Issued`] = Number(item.codes_issued);
      tempObj[`${item.app} Claimed`] = Number(item.codes_claimed);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyVerificationCodeItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj[`Issued`] = 0;
    tempObj[`Claimed`] = 0;
    return tempObj;
  }

  fillZeroItems(formattedData, emptyVerificationCodeItem);

  return formattedData;
};

const analyticsEventNameToLabel = new Map([
  ['WH_ExposureNotificationSent_Low', 'Low exposure notification'],
  ['WH_ExposureNotificationSent_High', 'High exposure notification'],
  ['WH_PositiveDiagnosis_Shared', 'Positive diagnosis'],
  ['WH_ShareButton_Clicked', 'Share button clicked'],
  ['WH_AppAnalytics_OptIn', 'Opt-in'],
  ['WH_AppAnalytics_OptOut', 'Opt-out'],
  ['WH_PositiveDiagnosis_Deleted', 'Positive diagnosis deleted'],
]);

// TODO: add more generic way to handle this
// https://wehealth.atlassian.net/browse/APP-1849
const getCommunityName = (id: string, organizationName: string, property: string = 'name'): string => {
  const _commap = communityMap[organizationName];
  if (!_commap) return id;

  const _item = _commap[id];
  if (!_item) return id;

  return _item[property];
};

const findCommunityName = (id: string, organizationName: string, search?: string): string => {
  const _commap = communityMap[organizationName];
  if (!_commap) return id;

  const _items = _.filter(_commap, { [search || 'id']: id });
  const _item = _items[0];

  if (!_item) return id;

  return _item.name;
};

const formatActiveUsers = (
  data: any,
  activeUserKeys: string[],
  communityId: string,
  organizationName: string
): any[] => {
  const filterFunc = (i: any) => {
    return i.active_users !== 0 && (i.community === communityId || !activeUserKeys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      const communityName = getCommunityName(item.community, organizationName);
      // const communityName = item.community;

      // take any already logged value
      let value = tempObj[communityName] || 0;

      // add the value from the new event
      tempObj[communityName] = value + Number(item.active_users);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyActiveUsersCommunityItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj['active_users'] = 0;

    return tempObj;
  }

  fillZeroItems(formattedData, emptyActiveUsersCommunityItem);

  return formattedData;
};

const formatPositiveCases = (data: any, keys: string[], communityId: string, organizationName: string): any[] => {
  const filterFunc = (i: any) => {
    return i.value !== 0 && (i.community_id === communityId || !keys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      const communityName = getCommunityName(item.community_id, organizationName);

      // take any already logged value
      let value = tempObj[communityName] || 0;

      // add the value from the new event
      tempObj[communityName] = value + Number(item.value);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyPositiveCasesItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj['Cases'] = 0;

    return tempObj;
  }

  fillZeroItems(formattedData, emptyPositiveCasesItem);

  return formattedData;
};

const formatClicksByCommunity = (
  data: any,
  communityKeys: string[],
  communityId: string,
  organizationName: string
): any[] => {
  const filterFunc = (i: any) => {
    return i.clicks !== '0' && (i.community_code === communityId || !communityKeys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      const communityName = findCommunityName(item.community_code, organizationName, 'bitlyTag');

      // take any already logged value
      let value = tempObj[communityName] || 0;

      // add the value from the new event
      tempObj[communityName] = value + Number(item.clicks);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyClicksByCommunityItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj['Clicks'] = 0;

    return tempObj;
  }

  fillZeroItems(formattedData, emptyClicksByCommunityItem);

  return formattedData;
};

const formatClicksByScreen = (data: any): any[] => {
  const cleanData = data;

  const clickEventTagToLabel = new Map([
    ['NoExp', 'No Exposure'],
    ['LowExp', 'Low Exposure'],
    ['HighExp', 'High Exposure'],
    ['Pos', 'Positive Diagnosis'],
  ]);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  function addClick(tempObj: any, item: any, key: string | undefined) {
    if (key == undefined) return;

    // take any already logged value
    let value = tempObj[key] || 0;
    // add the value from the new event
    tempObj[key] = value + Number(item.clicks);
  }

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      addClick(tempObj, item, clickEventTagToLabel.get(item.application_code));
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyClicksByScreenItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');

    // this is needed to zero-fill the data
    // tooltip is hidden without the same keys
    clickEventTagToLabel.forEach((elem: any) => {
      tempObj[elem] = 0;
    });

    return tempObj;
  }

  fillZeroItems(formattedData, emptyClicksByScreenItem);

  return formattedData;
};

const formatAnalyticsData = (data: any, communityKeys: string[], communityId: string): any[] => {
  const filterFunc = (i: any) => {
    return i.clicks !== '0' && (i.sub_region_name === communityId || !communityKeys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  function addEvent(tempObj: any, item: any, key: string | undefined) {
    if (key == undefined) return;

    // take any already logged value
    let value = tempObj[key] || 0;
    // add the value from the new event
    tempObj[key] = value + Number(item.event_value);
  }

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      addEvent(tempObj, item, analyticsEventNameToLabel.get(item.event_name));
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyAnalyticsItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj[`No events`] = '';
    return tempObj;
  }

  fillZeroItems(formattedData, emptyAnalyticsItem);

  return formattedData;
};

const formatAnalyticsByCommunity = (data: any, communityKeys: string[], communityId: string): any[] => {
  const filterFunc = (i: any) => {
    return i.clicks !== '0' && (i.sub_region_name === communityId || !communityKeys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      // take any already logged value
      let value = tempObj[item.sub_region_name] || 0;
      // add the value from the new event
      tempObj[item.sub_region_name] = value + Number(item.opt_in_count);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyAnalyticsItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj[`No data`] = '';
    return tempObj;
  }

  fillZeroItems(formattedData, emptyAnalyticsItem);

  return formattedData;
};

const formatAppDownloads = (data: any): any[] => {
  const cleanData = data;

  const appStoreMap = new Map([
    ['apple', 'iOS'],
    ['google_play', 'Android'],
  ]);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.start_date?.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].start_date.value, 'YYYY-MM-DD').format('l');

    _.forEach(apps, (item) => {
      const store = appStoreMap.get(item.store) || '';
      tempObj[store] = item.downloads || 0;
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyAppDownloads(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj[`No data`] = '';
    return tempObj;
  }

  fillZeroItems(formattedData, emptyAppDownloads);

  return formattedData;
};

const safeColors = [
  '#543005',
  '#8c510a',
  '#bf812d',
  '#dfc27d',
  '#80cdc1',
  '#35978f',
  '#01665e',
  '#003c30',
  '#543005',
  '#8c510a',
  '#bf812d',
  '#dfc27d',
  '#80cdc1',
  '#35978f',
  '#01665e',
  '#003c30',
];

// Use a copy of the array and reversed it
const safeColorReversed = safeColors.slice().reverse();

const bitlyColors = [
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#bc80bd',
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#bc80bd',
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#bc80bd',
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#bc80bd',
];

const getKeysForVerificationCodes = (data: any) => {
  let appKeys: any = _.groupBy(data, function (item) {
    return item.app;
  });

  appKeys = Object.keys(appKeys).sort();
  return appKeys;
};

const getKeysForCommunities = (data: any): string[] => {
  const cleanData = _.filter(data, (i) => {
    return i.clicks !== '0';
  });

  const clicksKeys = _.groupBy(cleanData, (item) => {
    return item.community_code;
  });

  return Object.keys(clicksKeys).sort();
};

const getKeysForActiveUsers = (data: any): string[] => {
  const cleanData = _.filter(data, (i) => {
    return i.active_users !== 0;
  });

  const keys = _.groupBy(cleanData, (item) => {
    return item.community;
  });

  return Object.keys(keys).sort();
};

const getKeysForOptIn = (data: any): string[] => {
  const clicksKeys = _.groupBy(data, (item) => {
    return item.sub_region_name;
  });

  return Object.keys(clicksKeys).sort();
};

const getKeysForPositiveCases = (data: any): string[] => {
  const keys = _.groupBy(data, (item) => {
    return item.community_id;
  });

  return Object.keys(keys).sort();
};

const CustomTooltip = (obj: any) => {
  const { active, payload }: { active: any; payload: any } = obj;

  if (!active || !payload) return null;

  let total: string;
  let getColor: (name: string) => string;
  let payloadData: [] = [];

  if (payload.length) {
    payloadData = payload[0].payload;

    const numOr0 = (n: any) => (isNaN(n) ? 0 : n);

    const sumValues = (obj: any) => Object.values(obj).reduce((a, b) => numOr0(a) + numOr0(b));
    const colors: any = [];

    payload.map((p: any) => colors.push({ color: p.fill, name: p.name }));

    getColor = (name: string) => {
      const color = _.filter(colors, (o) => {
        return o.name === name;
      });

      if (color.length) {
        return color[0].color;
      }
      return '';
    };
    total = `${sumValues(payloadData)}`;
  } else {
    total = '0';
  }

  return (
    <CustomTooltipWrapper>
      <p className="label">{`Total : ${total}`}</p>
      {Object.keys(payloadData).map((o, i) => {
        return <p style={{ color: getColor(o) }} key={i}>{`${o}: ${Object.values(payloadData)[i]}`}</p>;
      })}
    </CustomTooltipWrapper>
  );
};

const mapStore = ({ user, organization }: { user: any; organization: any }) => ({
  user,
  organization,
});

const Dashboard = observer(() => {
  const history = useHistory();
  const { community: communityId }: any = useParams();
  const [communityIndexActiveUsers, setCommunityIndexActiveUsers] = useState<number>(0);

  const { user, organization } = useInject(mapStore);

  const [range, setRange] = useState(30);

  const [verificationCodes, setVerificationCodes] = useState<any[]>([]);
  const [verificationCodesRange, setVerificationCodesRange] = useState<any[]>([]);
  const [verificationCodeKeys, setVerificationCodeKeys] = useState([]);

  const [positiveCases, setPositiveCases] = useState<any[]>([]);
  const [positiveCasesRange, setPositiveCasesRange] = useState<any[]>([]);
  const [positiveCaseKeys, setPositiveCaseKeys] = useState<any[]>([]);

  const [percentPosCodesClaimed, setPercentPosCodesClaimed] = useState<any[]>([]);
  const [percentPosCodesClaimedRange, setPercentPosCodesClaimedRange] = useState<any[]>([]);

  const [percentPosCases, setPercentPosCases] = useState<any[]>([]);
  const [percentPosCasesRange, setPercentPosCasesRange] = useState<any[]>([]);

  const [analyticsData, setAnalyticsData] = useState<any[]>([]);
  const [analyticsDataRange, setAnalyticsDataRange] = useState<any[]>([]);

  const [clicksByScreen, setClicksByScreen] = useState<any[]>([]);
  const [clicksByScreenRange, setClicksByScreenRange] = useState<any[]>([]);

  const [activeUsers, setActiveUsers] = useState<any[]>([]);
  const [activeUsersRange, setActiveUsersRange] = useState<any[]>([]);
  const [activeUserKeys, setActiveUserKeys] = useState<string[]>([]);

  const [clicksByCommunity, setClicksByCommunity] = useState<any[]>([]);
  const [clicksByCommunityRange, setClicksByCommunityRange] = useState<any[]>([]);
  const [communityKeys, setCommunityKeys] = useState<string[]>([]);

  const [analyticsByCommunity, setAnalyticsByCommunity] = useState<any[]>([]);
  const [analyticsByCommunityRange, setAnalyticsByCommunityRange] = useState<any[]>([]);
  const [analyticsByCommunityKeys, setAnalyticsByCommunityKeys] = useState<string[]>([]);

  const [appDownloads, setAppDownloads] = useState<any[]>([]);
  const [appDownloadsRange, setAppDownloadsRange] = useState<any[]>([]);

  const { data: positiveCasesData, isLoading: isLoadingPositiveCases } = useQuery(
    ['positive_cases', communityId],
    () => getPositiveCases(organization.name, communityId),
    useQuerySettings
  );

  const { data: verificationCodesData, isLoading: isLoadingVerificationCodes } = useQuery(
    ['verification_codes', communityId],
    () => getVerificationCodes(organization.name, communityId),
    useQuerySettings
  );

  const { data: exposuresData, isLoading: isLoadingExposures } = useQuery(
    'exposureNotifications',
    () => getExposureNotifications(organization.name),
    useQuerySettings
  );

  const { data: activeUsersData, isLoading: isLoadingActiveUsers } = useQuery(
    'active-users_community',
    () => getActiveUsers(organization.name),
    useQuerySettings
  );

  const { data: clicksDataApp, isLoading: isLoadingClicksApp } = useQuery(
    ['application_code', communityId],
    () => getBitlyClicks('application_code', communityId),
    useQuerySettings
  );
  const { data: clicksDataCom, isLoading: isLoadingClicksCom } = useQuery(
    ['community_code', communityId],
    () => getBitlyClicks('community_code', communityId),
    useQuerySettings
  );

  const { data: analyticsByCommunityData, isLoading: isLoadingAnalyticsByCommunity } = useQuery(
    'opt-ins',
    () => getAppAnalytics(organization.name),
    useQuerySettings
  );

  const { data: appDownloadsData, isLoading: isLoadingAppDownloads } = useQuery(
    'app-downloads',
    () => getAppDownloads(organization.name),
    useQuerySettings
  );

  useEffect(() => {
    const _keys = getKeysForActiveUsers(activeUsersData);

    setActiveUserKeys(_keys);
    setActiveUsers(formatActiveUsers(activeUsersData, _keys, communityId, organization?.name));
    setCommunityIndexActiveUsers(_keys.indexOf(communityId) || 0);
  }, [isLoadingActiveUsers, range, communityId]);

  useEffect(() => {
    setActiveUsersRange(activeUsers.slice(-range));
  }, [activeUsers, range, communityId]);

  useEffect(() => {
    const _keys = getKeysForPositiveCases(positiveCasesData);

    setPositiveCaseKeys(_keys);
    setPositiveCases(formatPositiveCases(positiveCasesData, _keys, communityId, organization?.name));
  }, [isLoadingPositiveCases, range, communityId]);

  useEffect(() => {
    setPositiveCasesRange(positiveCases.slice(-range));
  }, [positiveCases, range, communityId]);

  useEffect(() => {
    setClicksByScreen(formatClicksByScreen(clicksDataApp));
  }, [isLoadingClicksApp, range, communityId]);

  useEffect(() => {
    setClicksByScreenRange(clicksByScreen.slice(-range));
  }, [clicksByScreen, range, communityId]);

  useEffect(() => {
    const _keys = getKeysForCommunities(clicksDataCom);
    const _commap = communityMap[organization?.name];
    if (!_commap) return;

    const _communityId = _commap[communityId]?.bitlyTag;

    setCommunityKeys(_keys);
    setClicksByCommunity(formatClicksByCommunity(clicksDataCom, _keys, _communityId, organization?.name));
  }, [isLoadingClicksCom, range, communityId]);

  useEffect(() => {
    setClicksByCommunityRange(clicksByCommunity.slice(-range));
  }, [clicksByCommunity, range, communityId]);

  // Opt-in Analytics by Event
  useEffect(() => {
    const _keys = getKeysForOptIn(exposuresData);
    const _commap = communityMap[organization?.name];
    if (!_commap) return;

    const _communityId = _commap[communityId]?.name;

    setAnalyticsData(formatAnalyticsData(exposuresData, _keys, _communityId));
  }, [isLoadingExposures, range, communityId]);

  useEffect(() => {
    setAnalyticsDataRange(analyticsData.slice(-range));
  }, [analyticsData, range, communityId]);

  // Opt-in Analytics by Community
  useEffect(() => {
    const _keys = getKeysForOptIn(analyticsByCommunityData);
    const _commap = communityMap[organization?.name];
    if (!_commap) return;

    const _communityId = _commap[communityId]?.name;

    setAnalyticsByCommunity(formatAnalyticsByCommunity(analyticsByCommunityData, _keys, _communityId));
    setAnalyticsByCommunityKeys(_keys);
  }, [isLoadingAnalyticsByCommunity, range, communityId]);

  useEffect(() => {
    setAnalyticsByCommunityRange(analyticsByCommunity.slice(-range));
  }, [analyticsByCommunity, range, communityId]);

  // Verification Code by Source
  useEffect(() => {
    const data = formatVerificationCodes(verificationCodesData);
    setVerificationCodes(data);
    setVerificationCodeKeys(getKeysForVerificationCodes(verificationCodesData));
  }, [isLoadingVerificationCodes, range, communityId]);

  useEffect(() => {
    setVerificationCodesRange(verificationCodes.slice(-range));
  }, [verificationCodes, range, communityId]);

  useEffect(() => {
    setAppDownloads(formatAppDownloads(appDownloadsData));
  }, [isLoadingAppDownloads, range]);

  useEffect(() => {
    setAppDownloadsRange(appDownloads.slice(-range));
  }, [appDownloads, range]);

  // % of positive cases that share a diagnosis
  useEffect(() => {
    const _commap = communityMap[organization?.name];
    if (!_commap) return;

    if (verificationCodes.length === 0 || positiveCases.length === 0) {
      setPercentPosCases([]);
      setPercentPosCodesClaimed([]);
      return;
    }

    const _name = _commap[communityId]?.name;

    const percentPosCodesClaimedData: any = [];
    const percentPosCasesData: any = [];

    const rollingDays = 7;

    positiveCases.forEach((item, i) => {
      const arrayStart = Math.max(0, i - (rollingDays - 1)); // subtract 6 days
      const arrayEnd = i + 1;
      const arrayRange = positiveCases.slice(arrayStart, arrayEnd);

      let rollingAvgPositives = 0;
      let rollingAvgClaims = 0;

      arrayRange.forEach((_item, _j) => {
        const en = _.find(verificationCodes, { date: _item.date });
        const positives = _item[_name] || 0;
        const claimedValues: any[] = Object.entries(en || {}).filter((e) => e[0]?.includes('Claimed'));
        const claimed = claimedValues.reduce((x: any, y: any) => x + y[1], 0);

        rollingAvgPositives += positives;
        rollingAvgClaims += claimed;
      });

      rollingAvgPositives = Math.round(rollingAvgPositives / rollingDays);
      rollingAvgClaims = rollingAvgClaims / rollingDays;
      rollingAvgClaims = rollingAvgClaims > rollingAvgPositives ? 0 : rollingAvgClaims;

      let percentageOfPositiveCasesClaimed = rollingAvgClaims / rollingAvgPositives;
      percentageOfPositiveCasesClaimed = Math.round(percentageOfPositiveCasesClaimed * 100);
      percentageOfPositiveCasesClaimed = isFinite(percentageOfPositiveCasesClaimed)
        ? percentageOfPositiveCasesClaimed
        : 0;

      percentPosCodesClaimedData.push({
        [_name]: percentageOfPositiveCasesClaimed,
        date: item.date,
      });

      percentPosCasesData.push({
        [_name]: rollingAvgPositives,
        date: item.date,
      });
    });

    setPercentPosCodesClaimed(percentPosCodesClaimedData);
    setPercentPosCases(percentPosCasesData);
  }, [verificationCodes, positiveCases, communityId]);

  useEffect(() => {
    setPercentPosCodesClaimedRange(percentPosCodesClaimed.slice(-range));
    setPercentPosCasesRange(percentPosCases.slice(-range));
  }, [percentPosCodesClaimed, percentPosCases]);

  const communityOnChange = async (e: any) => {
    const _communityId = e.target.value;

    if (_communityId === 'all') {
      history.push(`/dashboard`);
      return;
    }

    history.push(`/dashboard/${_communityId}`);
  };

  let egCopy =
    'E.g. “Residents” shows how many clicks were made by all the users who have chosen “Residents” as their community.';
  if (organization.name === organizationName.arizona) {
    egCopy =
      'E.g. “az_maricopa” shows how many clicks were made by all the users who have chosen Maricopa as their community.';
  }
  return !user.isSignedIn ||
    !user.isAdmin ||
    user.isFirstTimeUser ||
    (user.passwordResetRequested && user.signedInWithEmailLink) ? (
    <Redirect to={ROUTES.LANDING} />
  ) : (
    <div className="module-container" id="diagnosis-codes">
      <PageTitle title="Dashboard" />
      <h1>Dashboard (Last {range} days)</h1>

      <CommunitySelectContainer>
        {/* <CommunitySelectTitle>Select a community:</CommunitySelectTitle> */}
        <CommunitySelectWrapper>
          <CommunitySelect value={communityId} onChange={communityOnChange}>
            <CommunityOption value="all">-- Summary Dashboard --</CommunityOption>
            {communityMap[organization?.name] &&
              Object.values(communityMap[organization.name]).map((community: any) => (
                <CommunityOption value={community.id}>{community.name}</CommunityOption>
              ))}
          </CommunitySelect>
        </CommunitySelectWrapper>
      </CommunitySelectContainer>

      <ButtonGroup style={{ marginLeft: 0 }} variant="text" color="primary" aria-label="text primary button group">
        <Button
          onClick={() => {
            setRange(30);
          }}
        >
          Last 30 days
        </Button>
        <Button
          onClick={() => {
            setRange(60);
          }}
        >
          Last 60 days
        </Button>
        <Button
          onClick={() => {
            setRange(90);
          }}
        >
          Last 90 Days
        </Button>
      </ButtonGroup>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledPaper>
            <ChartHeading>Daily Active Users by Community (Last {range} Days)</ChartHeading>

            <ChartSubHeading>
              This is a fully anonymous, aggregate count of active devices in this community based on exposure checks on
              the server.
            </ChartSubHeading>
            {!activeUsersRange.length ? (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            ) : (
              <ResponsiveContainer width="100%" height={320}>
                <BarChart
                  barGap={0}
                  data={activeUsersRange}
                  margin={{
                    top: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" interval="preserveStartEnd" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />

                  {activeUserKeys.includes(communityId) ? (
                    <Bar
                      key={communityId}
                      barSize={20}
                      dataKey={getCommunityName(communityId, organization?.name)}
                      stackId="a"
                      fill={bitlyColors[communityIndexActiveUsers]}
                    />
                  ) : (
                    activeUserKeys.map((label: string, index: number) => (
                      <Bar
                        key={label}
                        barSize={20}
                        dataKey={getCommunityName(label, organization?.name)}
                        stackId="a"
                        fill={bitlyColors[index]}
                      />
                    ))
                  )}
                </BarChart>
              </ResponsiveContainer>
            )}
          </StyledPaper>
        </Grid>

        {positiveCasesRange.length > 0 && (
          <Grid item xs={12}>
            <StyledPaper>
              <ChartHeading>Positive Cases by Community (Last {range} Days)</ChartHeading>

              <ChartSubHeading>Positive Cases by Community</ChartSubHeading>
              {!positiveCasesRange.length ? (
                <LoadingWrapper>
                  <CircularProgress />
                </LoadingWrapper>
              ) : (
                <ResponsiveContainer width="100%" height={320}>
                  <BarChart
                    barGap={0}
                    data={positiveCasesRange}
                    margin={{
                      top: 20,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" interval="preserveStartEnd" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />

                    {positiveCaseKeys.includes(communityId) ? (
                      <Bar
                        key={communityId}
                        barSize={20}
                        dataKey={getCommunityName(communityId, organization?.name)}
                        stackId="a"
                        fill={safeColors[0]}
                      />
                    ) : (
                      positiveCaseKeys.map((label: string, index: number) => (
                        <Bar
                          key={label}
                          barSize={20}
                          dataKey={getCommunityName(label, organization?.name)}
                          stackId="a"
                          fill={bitlyColors[index]}
                        />
                      ))
                    )}
                    <Bar dataKey={'Cases'} stackId="a" legendType={'none'} />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </StyledPaper>
          </Grid>
        )}

        <Grid item xs={12}>
          <StyledPaper>
            <ChartHeading>Clicks by App Screen (Last {range} Days)</ChartHeading>

            <ChartSubHeading>
              When a user clicks on a link within the app it can be reported on in the aggregate. This shows how those
              clicks break down by the various app screens the user can be viewing. E.g. “High Exposure” shows how many
              clicks were made on the screen users see when they get a High Exposure alert. This doesn't require opt-in
              so the data is representative of the entire user base.
            </ChartSubHeading>
            {!clicksByScreenRange.length ? (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            ) : (
              <ResponsiveContainer width="100%" height={320}>
                <BarChart
                  barGap={0}
                  data={clicksByScreenRange}
                  margin={{
                    top: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" interval="preserveStartEnd" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />

                  <Bar barSize={20} dataKey="No Exposure" stackId="a" fill="#abdda4" />
                  <Bar barSize={20} dataKey="Low Exposure" stackId="a" fill="#fdae61" />
                  <Bar barSize={20} dataKey="High Exposure" stackId="a" fill="#d53e4f" />
                  <Bar barSize={20} dataKey="Positive Diagnosis" stackId="a" fill="#9e0142" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <ChartHeading>Clicks by Community (Last {range} Days)</ChartHeading>
            <ChartSubHeading>
              When a user clicks on a link within the app it can be reported on in the aggregate. This shows how those
              clicks break down by the community that the user has chosen. {egCopy} This doesn't require opt-in so the
              data is representative of the entire user base.
            </ChartSubHeading>
            {!clicksByCommunityRange.length ? (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            ) : (
              <ResponsiveContainer width="100%" height={420}>
                <BarChart
                  barGap={0}
                  data={clicksByCommunityRange}
                  margin={{
                    top: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" interval="preserveStartEnd" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />

                  {activeUserKeys.includes(communityId) ? (
                    <Bar
                      key={communityId}
                      barSize={20}
                      dataKey={communityMap[organization?.name][communityId]?.name}
                      stackId="a"
                      fill={bitlyColors[communityIndexActiveUsers]}
                    />
                  ) : (
                    communityKeys.map((label: string, index: number) => (
                      <Bar
                        key={label}
                        barSize={20}
                        dataKey={findCommunityName(label, organization?.name, 'bitlyTag')}
                        stackId="a"
                        fill={bitlyColors[index]}
                      />
                    ))
                  )}
                  <Bar dataKey={`Clicks`} stackId="a" legendType={'none'} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <ChartHeading>Opt-in Analytics by Event (Last {range} Days)</ChartHeading>
            <ChartSubHeading>
              The app allows users to explicitly opt-in to sharing anonymous analytics. This chart shows the total
              number of analytics events aggregated by the type of event. Since this requires opt-in the activity here
              is just a sample of the entire user base.
            </ChartSubHeading>
            {!analyticsDataRange.length ? (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            ) : (
              <ResponsiveContainer width="100%" height={320}>
                <BarChart
                  barGap={0}
                  data={analyticsDataRange}
                  margin={{
                    top: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" interval="preserveStartEnd" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar barSize={20} dataKey="Low exposure notification" stackId="a" fill="#fdae61" />
                  <Bar barSize={20} dataKey="High exposure notification" stackId="a" fill="#d53e4f" />
                  <Bar barSize={20} dataKey="Positive diagnosis" stackId="a" fill="#9e0142" />
                  <Bar barSize={20} dataKey="Share button clicked" stackId="a" fill="#5e4fa2" />
                  <Bar barSize={20} dataKey="Opt-in" stackId="a" fill="#66c2a5" />
                  <Bar barSize={20} dataKey="Opt-out" stackId="a" fill="#1a9850" />
                  <Bar barSize={20} dataKey="Positive diagnosis deleted" stackId="a" fill="#3288bd" />
                  <Bar barSize={20} dataKey="No events" stackId="a" legendType={'none'} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <ChartHeading>Opt-in Analytics by Community (Last {range} Days)</ChartHeading>
            <ChartSubHeading>
              The app allows users to explicitly opt-in to sharing anonymous analytics. This chart shows the total
              number of analytics events aggregated by the community in which it occurred. Since this requires opt-in
              the activity here is just a sample of the entire user base.
            </ChartSubHeading>
            {!analyticsByCommunityRange.length ? (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            ) : (
              <ResponsiveContainer width="100%" height={320}>
                <BarChart
                  barGap={0}
                  data={analyticsByCommunityRange}
                  margin={{
                    top: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" interval="preserveStartEnd" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    margin={{
                      top: 200,
                      bottom: 0,
                    }}
                  />

                  {activeUserKeys.includes(communityId) ? (
                    <Bar
                      key={communityMap[organization?.name][communityId]?.name}
                      barSize={20}
                      dataKey={communityMap[organization?.name][communityId]?.name}
                      stackId="a"
                      fill={bitlyColors[communityIndexActiveUsers]}
                    />
                  ) : (
                    analyticsByCommunityKeys.map((label: string, index: number) => (
                      <Bar barSize={20} dataKey={label} stackId="a" fill={safeColors[index]} key={index} />
                    ))
                  )}
                  <Bar barSize={20} dataKey="No data" stackId="a" legendType={'none'} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <ChartHeading>Verification Code by Source (Last {range} days)</ChartHeading>
            <ChartSubHeading>
              When a user tests positive they need to enter a verification code to enter the diagnosis in the app to
              notify everyone anonymously. This chart shows the total number of codes issued grouped by the source where
              the code was issued e.g. pha, labs, apps, crm etc. This data is reported from the server side and doesn't
              require any opt-in. It shows activity of the PHA and partners.
            </ChartSubHeading>
            {!verificationCodesRange.length ? (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            ) : (
              <ResponsiveContainer width="100%" height={420}>
                <BarChart barGap={0} data={verificationCodesRange}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" interval="preserveStartEnd" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  {verificationCodeKeys.map((label: string, index: number) => (
                    <Bar
                      barSize={10}
                      dataKey={`${label} Issued`}
                      stackId="a"
                      fill={safeColors[index]}
                      key={`issued-${index}`}
                    />
                  ))}
                  {verificationCodeKeys.map((label: string, index: number) => (
                    <Bar barSize={10} dataKey={`${label} Claimed`} stackId="b" fill={safeColorReversed[index]} />
                  ))}
                  <Bar dataKey={`Issued`} stackId="a" legendType={'none'} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </StyledPaper>
        </Grid>

        {percentPosCasesRange.length > 0 && (
          <Grid item xs={12}>
            <StyledPaper>
              <ChartHeading>Positive Cases by Community (7-day moving average, last {range} Days)</ChartHeading>

              <ChartSubHeading>Positive Cases by Community</ChartSubHeading>
              {!percentPosCasesRange.length ? (
                <LoadingWrapper>
                  <CircularProgress />
                </LoadingWrapper>
              ) : (
                <ResponsiveContainer width="100%" height={320}>
                  <BarChart
                    barGap={0}
                    data={percentPosCasesRange}
                    margin={{
                      top: 20,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" interval="preserveStartEnd" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />

                    {positiveCaseKeys.includes(communityId) ? (
                      <Bar
                        key={communityId}
                        barSize={20}
                        dataKey={getCommunityName(communityId, organization?.name)}
                        stackId="a"
                        fill={safeColors[0]}
                      />
                    ) : (
                      positiveCaseKeys.map((label: string, index: number) => (
                        <Bar
                          key={label}
                          barSize={20}
                          dataKey={getCommunityName(label, organization?.name)}
                          stackId="a"
                          fill={bitlyColors[index]}
                        />
                      ))
                    )}
                    <Bar dataKey={'Cases'} stackId="a" legendType={'none'} />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </StyledPaper>
          </Grid>
        )}

        {percentPosCodesClaimedRange.length > 0 && (
          <Grid item xs={12}>
            <StyledPaper>
              <ChartHeading>Codes Claimed (% of total pos cases, last {range} Days)</ChartHeading>

              <ChartSubHeading>Codes Claimed</ChartSubHeading>
              {!percentPosCodesClaimedRange.length ? (
                <LoadingWrapper>
                  <CircularProgress />
                </LoadingWrapper>
              ) : (
                <ResponsiveContainer width="100%" height={320}>
                  <BarChart
                    barGap={0}
                    data={percentPosCodesClaimedRange}
                    margin={{
                      top: 20,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" interval="preserveStartEnd" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />

                    {positiveCaseKeys.includes(communityId) ? (
                      <Bar
                        key={communityId}
                        barSize={20}
                        dataKey={getCommunityName(communityId, organization?.name)}
                        stackId="a"
                        fill={safeColors[0]}
                      />
                    ) : (
                      positiveCaseKeys.map((label: string, index: number) => (
                        <Bar
                          key={label}
                          barSize={20}
                          dataKey={getCommunityName(label, organization?.name)}
                          stackId="a"
                          fill={bitlyColors[index]}
                        />
                      ))
                    )}
                    <Bar dataKey={'Cases'} stackId="a" legendType={'none'} />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </StyledPaper>
          </Grid>
        )}

        <Grid item xs={12}>
          <StyledPaper>
            <ChartHeading>App Downloads (Last {range} Days)</ChartHeading>
            <ChartSubHeading>Daily App Downloads</ChartSubHeading>
            {!appDownloadsRange.length ? (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            ) : (
              <ResponsiveContainer width="100%" height={420}>
                <BarChart
                  barGap={0}
                  data={appDownloadsRange}
                  margin={{
                    top: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" interval="preserveStartEnd" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />

                  <Bar key="iOS" barSize={20} dataKey="iOS" stackId="a" fill={bitlyColors[1]} />
                  <Bar key="Android" barSize={20} dataKey="Android" stackId="a" fill={bitlyColors[0]} />

                  <Bar dataKey={`No data`} stackId="a" legendType={'none'} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </StyledPaper>
        </Grid>
      </Grid>

      <div className="" style={{ marginBottom: 300 }} />
    </div>
  );
});

export default Dashboard;
