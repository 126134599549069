/* eslint-disable class-methods-use-this */
import React from 'react';
import imageCompression from 'browser-image-compression';

import { defaultUser, defaultOrganization } from '@src/mobx/stores';

import { useStore } from '@src/components/utils/Mobx';

import {
  auth,
  db,
  analytics,
  createUserCallable,
  initiatePasswordRecoveryCallable,
  getVerificationCodeCallable,
} from '@src/config/initFirebase';

export const FirebaseMobxContext = React.createContext();

class FirebaseMobxProvider extends React.Component {
  constructor(props) {
    super(props);
    // Set auth persistence to SESSION for development so that every change registered by the hot-reload dev server doesn't log you out

    this.data = this.props.store;
    this.analytics = analytics;
    this.__userDocumentListener = null;
    this.__userImageListener = null;
    this.__organizationDocumentListener = null;
    this.__membersListener = null;
    this.__lastVisibleMember = null; // pagination helper
    this.__firstVisibleMember = null; // pagination helper

    this.__authStateListener = auth.onAuthStateChanged(async (user) => {
      this.data.user.update({ isLoading: true });

      if (user) {
        // get user's document from the db and at the same time set up a listener to respond to document changes
        if (this.__userDocumentListener === null) {
          this.__userDocumentListener = db
            .collection('users')
            .doc(user.email)
            .onSnapshot((updatedUserDocumentSnapshot) => {
              // update the store with data from this document and set isSignedIn to true
              this.data.user.update({
                ...updatedUserDocumentSnapshot.data(),
                email: updatedUserDocumentSnapshot.id,
                isSignedIn: true,
                isLoading: false,
              });

              // If DNE, set up organization listener within this callback,
              // since it relies on this.data.user.organizationID being set
              if (this.__organizationDocumentListener === null) {
                this.__organizationDocumentListener = db
                  .collection('organizations')
                  .doc(this.data.user.organizationID)
                  .onSnapshot((updatedOrganizationDocumentSnapshot) => {
                    this.data.organization.update({
                      ...updatedOrganizationDocumentSnapshot.data(),
                      id: updatedOrganizationDocumentSnapshot.id,
                      currentPage: this.data.organization.currentPage,
                    });
                    if (this.data.user.isAdmin && this.__membersListener === null) {
                      const newListener = db
                        .collection('users')
                        .where('organizationID', '==', this.data.organization.id)
                        .orderBy('lastName')
                        .orderBy('firstName')
                        .onSnapshot((membersSnapshot) => {
                          this.__updateMembersOnSnapshot(membersSnapshot.docs, newListener);
                        });
                    }
                  });
              }
            });
        }
        // Get and set up listener for user's image
        if (this.__userImageListener === null) {
          this.__userImageListener = db
            .collection('userImages')
            .doc(user.email)
            .onSnapshot((updatedUserImageDocumentSnapshot) => {
              if (updatedUserImageDocumentSnapshot.exists) {
                this.data.user.update({
                  imageBlob: updatedUserImageDocumentSnapshot.data().imageBlob,
                });
              }
            });
        }
      } else {
        // signed out
        // reset to default state
        this.data.user.update(defaultUser);
        this.data.organization.update(defaultOrganization);

        // detach listeners
        if (this.__userDocumentListener !== null) {
          this.__userDocumentListener();
          this.__userDocumentListener = null;
        }
        if (this.__userImageListener !== null) {
          this.__userImageListener();
          this.__userImageListener = null;
        }
        if (this.__organizationDocumentListener !== null) {
          this.__organizationDocumentListener();
          this.__organizationDocumentListener = null;
        }
        if (this.__membersListener !== null) {
          this.__membersListener();
          this.__membersListener = null;
        }
      }
    });
  }

  __updateMembersOnSnapshot(membersSnapshotDocs, newListener) {
    if (membersSnapshotDocs.length > 0) {
      if (this.__membersListener !== newListener) {
        this.__membersListener = newListener;
      }
      // Based on https://firebase.google.com/docs/firestore/query-data/query-cursors#paginate_a_query
      this.__firstVisibleMember = membersSnapshotDocs[0];
      this.__lastVisibleMember = membersSnapshotDocs[membersSnapshotDocs.length - 1];
      this.data.organization.setMembers(
        // See https://stackoverflow.com/a/24806827
        membersSnapshotDocs.reduce((result, userDoc) => {
          if (userDoc.id !== this.data.user.email) {
            result.push({ ...userDoc.data(), email: userDoc.id });
          }
          return result;
        }, [])
      );
    }
  }

  async updateUser(updates) {
    try {
      await db.collection('users').doc(this.data.user.email).update(updates);
    } catch (err) {}
  }

  async updateUserImage(imageBlob) {
    // .set() with { merge: true } so that if the document dne, it's created, otherwise its updated
    const options = { maxSizeMB: 0.6, maxWidthOrHeight: 900 };
    const compressedFile = await imageCompression(imageBlob, options);
    const dataURL = await imageCompression.getDataUrlFromFile(compressedFile);
    await db.collection('userImages').doc(this.data.user.email).set({ imageBlob: dataURL }, { merge: true });
  }

  async updateOrganization(updates) {
    await db.collection('organizations').doc(this.data.organization.id).update(updates);
  }

  async signOut() {
    await auth.signOut();
  }

  async updatePassword(password) {
    await auth.currentUser.updatePassword(password);
  }

  async createUser(newUser) {
    const result = await createUserCallable(newUser);
    return result.data;
  }

  async sendPasswordRecoveryEmail(email) {
    await initiatePasswordRecoveryCallable({ email });
    // Save the email locally so you don't need to ask the user for it again if they open the link on the same device.
    // See https://firebase.google.com/docs/auth/web/email-link-auth#send_an_authentication_link_to_the_users_email_address
    window.localStorage.setItem('emailForSignIn', email);
    return true;
  }

  async updateUserByEmail(email, updates) {
    await db.collection('users').doc(email).update(updates);
  }

  // issueCodeRequest looks like {testType: "likely", symptomDate: "2020-07-02"} or {testType: "confirmed", symptomDate: "2020-07-02"}
  async getVerificationCode(issueCodeRequest) {
    const verificationCode = await getVerificationCodeCallable(issueCodeRequest);
    return verificationCode;
  }

  isSignInWithEmailLink(link) {
    return auth.isSignInWithEmailLink(link);
  }

  setPasswordResetCompletedInCurrentSession(val) {
    this.data.user.update({ passwordResetCompletedInCurrentSession: val });
  }

  render() {
    return <FirebaseMobxContext.Provider value={this}>{this.props.children}</FirebaseMobxContext.Provider>;
  }
}

export default ({ children }) => {
  const store = useStore();

  return <FirebaseMobxProvider store={store}>{React.Children.only(children)}</FirebaseMobxProvider>;
};
