import React from 'react';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import mapValues from 'lodash/mapValues';
import isString from 'lodash/isString';
import deepmerge from 'deepmerge';
import { Grid } from '@material-ui/core';

import { LabelValuePair, AppSettings } from '@src/lib/types';

import Select from '@src/components/formFields/Select';
import NumberInput from '@src/components/formFields/NumberInput';
import Button from '@src/components/buttons/Button';

import SaveConfirmationModal from '../modals/SaveConfirmation';
import CancelConfirmation from '../modals/CancelConfirmation';

import RiskLevelDescription from './common/RiskLevelDescription';

type Props = {
  regionOptions: LabelValuePair[];
  selectedRegion: LabelValuePair['value'] | undefined;
  onSelectRegion: (option: LabelValuePair['value']) => void;
  appSettings?: AppSettings;
  onRiskModelUpdate: (riskModel: AppSettings['riskModelConfiguration'], onSuccessCallback: () => void) => void;
  isRiskModelUpdateLoading: boolean;
};

const RiskModelContainer = styled.div`
  padding: 100px 0;
`;

const RiskModelHeader = styled.h1`
  margin-top: 0;
  margin-bottom: 50px;
`;

const RiskModelRegionSelectWrapper = styled.div`
  margin-bottom: 100px;
`;

const SelectLabelGroup = styled.div`
  display: inline-flex;
  align-items: center;

  select:not(:last-of-type) {
    margin-right: 30px;
  }
`;

const StyledLabel = styled.label`
  font-size: 18px;
  font-weight: 600;
  margin-right: 30px;
`;

const RiskModelDescriptionInputGroup = styled(Grid)`
  // display: flex;
  // flex-direction: column;
  // max-width: 325px;

  // margin-right: 15px;
  margin-bottom: 100px;
`;

const RiskModelThresholdModelsContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px !important;
`;

const LabelInputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  margin-top: 30px;
`;

const InputLabel = styled.label`
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  margin-right: 20px;
`;

const StyledThresholdInput = styled(NumberInput)`
  width: 80px !important;
  text-align: right;
  border-width: 1px !important;
  padding: 0 7px 0 10px !important;
`;

const ThresholdDescription = styled.span`
  font-weight: 400;
  size: 14px;
  line-height: 20px;
`;

const FormButtonGroup = styled.div`
  button:first-of-type {
    margin-right: 30px;
  }
`;

const DEFAULT_MODAL_STATE = {
  type: 'save', // type: 'save' | 'cancel'
  isOpen: false,
};

const RiskModel = ({
  selectedRegion,
  regionOptions,
  onSelectRegion,
  appSettings,
  onRiskModelUpdate,
  isRiskModelUpdateLoading,
}: Props) => {
  const methods = useForm<AppSettings['riskModelConfiguration']>();
  const [modalState, setModalState] = React.useState(DEFAULT_MODAL_STATE);
  const { control, reset, handleSubmit } = methods;

  React.useEffect(() => {
    if (appSettings?.riskModelConfiguration) {
      reset(appSettings.riskModelConfiguration);
    }
  }, [appSettings?.riskModelConfiguration]);

  const onResetForm = () => reset(appSettings?.riskModelConfiguration);
  const onToggleModal = () => setModalState((s) => ({ ...s, isOpen: !s.isOpen }));
  const onOpenConfirmationModal = () => setModalState({ type: 'save', isOpen: true });
  const onOpenCancelModal = () => setModalState({ type: 'cancel', isOpen: true });

  const onSubmitForm = React.useCallback(async () => {
    await handleSubmit((d) => {
      if (appSettings) {
        const newRiskModelConfig = mapValues(d, (o) =>
          isString(o) ? Number(o) : o
        ) as AppSettings['riskModelConfiguration'];
        const newRiskModel = deepmerge(appSettings.riskModelConfiguration, newRiskModelConfig);
        onRiskModelUpdate(newRiskModel, onToggleModal);
      }
    })();
  }, [appSettings, onToggleModal, onRiskModelUpdate]);

  return (
    <FormProvider {...methods}>
      <RiskModelContainer className="module-container">
        <RiskModelHeader>Risk Model</RiskModelHeader>
        <RiskModelRegionSelectWrapper>
          <SelectLabelGroup>
            <StyledLabel>Region/County</StyledLabel>
            <Select
              placeholder="Select"
              onChange={(e: string) => onSelectRegion(Number(e))}
              name="region"
              options={regionOptions}
              value={selectedRegion}
            />
          </SelectLabelGroup>
        </RiskModelRegionSelectWrapper>

        <RiskModelThresholdModelsContainer container spacing={10}>
          <RiskModelDescriptionInputGroup item xs={3}>
            <RiskLevelDescription riskType="lowThreshold" />
            <LabelInputGroup>
              <InputLabel>Low Threshold</InputLabel>
              <StyledThresholdInput
                isAllowed={({ value }: any) => value < 100}
                name="lowExposureDuration"
                control={control}
                suffix=" MEM"
              />
            </LabelInputGroup>
            <ThresholdDescription>
              When total discounted exposures in the last 14 days is above this threshold it triggers the &quot;Some
              Exposure in the past 14 days&quot; state.
            </ThresholdDescription>
          </RiskModelDescriptionInputGroup>
          <RiskModelDescriptionInputGroup item xs={3}>
            <RiskLevelDescription riskType="highThreshold" />
            <LabelInputGroup>
              <InputLabel>High Threshold</InputLabel>
              <StyledThresholdInput
                isAllowed={({ value }: any) => value < 100}
                name="highExposureDuration"
                control={control}
                suffix=" MEM"
              />
            </LabelInputGroup>
            <ThresholdDescription>
              When total discounted exposures in the last 14 days is above the threshold it triggers the &quot;High
              Exposure in the past 14 days&quot; state.
            </ThresholdDescription>
          </RiskModelDescriptionInputGroup>
        </RiskModelThresholdModelsContainer>

        <SaveConfirmationModal
          isOpen={modalState.type === 'save' && modalState.isOpen}
          onToggleModal={onToggleModal}
          onSubmitForm={onSubmitForm}
          isLoading={isRiskModelUpdateLoading}
        />
        <CancelConfirmation
          isOpen={modalState.type === 'cancel' && modalState.isOpen}
          onToggleModal={onToggleModal}
          onResetForm={onResetForm}
        />
      </RiskModelContainer>
    </FormProvider>
  );
};

export default RiskModel;
