const currentEnv = process.env.NODE_ENV || 'development';

const apisUrls: { [key: string]: string } = {
  local: 'http://localhost:5002/api',
  development: 'https://wehealth-portal-dev.web.app/api',
  staging: 'https://demo-portal.wehealth.org/api',
  production: 'https://portal.wehealth.org/api',
};

const publicApiUrl: { [key: string]: string } = {
  development: 'https://api-dev.wehealth.org/1',
  staging: 'https://api-staging.wehealth.org/1',
  production: 'https://api.wehealth.org/1',
};

const firebaseUrls: { [key: string]: string } = {
  development: 'https://org-wehealth-demo.firebaseio.com',
  staging: 'https://org-wehealth-demo.firebaseio.com',
  production: 'https://org-wehealth.firebaseio.com',
};

export const firebaseDbName: { [key: string]: string } = {
  az: 'gov-azdhs-covidwatch',
  bm: 'org-wehealth-exposure',
};

export const API_URL = apisUrls[currentEnv] || apisUrls['development'];
export const API_URL_PUBLIC = publicApiUrl[currentEnv] || publicApiUrl['development'];
export const FIREBASE_URL = firebaseUrls[currentEnv] || apisUrls['development'];

export const allowedOrigins = [
  'https://wehealth-portal-dev.web.app',
  'https://demo-portal.wehealth.org',
  'https://wehealth-portal-staging.web.app',
  'https://portal.wehealth.org',
  'https://wehealth-portal.web.app',
  'http://localhost:3000',
];
