// @ts-nocheck

import React from 'react';
import styled from 'styled-components';

import none from '@src/assets/none.png';
import low from '@src/assets/low.png';
import high from '@src/assets/high.png';
import verified from '@src/assets/verified.png';
import highThreshold from '@src/assets/high-threshold.png';
import code from '@src/assets/code.png';
import vaccine from '@src/assets/vaccine.svg';
import lowThreshold from '@src/assets/lowRisk.png';

type RiskType = 'medium' | 'high' | 'verified' | string;

const PhoneThresholdClassificationGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 326px;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url(${(props) => props.img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const RISK_LEVEL_IMAGES: { [key: string]: string } = {
  none,
  low,
  high,
  verified,
  highThreshold,
  code,
  vaccine,
  lowThreshold,
};

type Props = {
  riskType: RiskType;
};

const RiskLevelDescription = ({ riskType = '' }: Props) => {
  return <PhoneThresholdClassificationGroup img={RISK_LEVEL_IMAGES[riskType]} />;
};

export default RiskLevelDescription;
