import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useInject } from '@src/utils/hooks/mobx';
import { RootStoreModel } from '@src/mobx/stores/Root';

import MainLayout from '@src/components/layouts/MainLayout';

type Props = {} & RouteProps;

const mapStore = ({ user, organization }: RootStoreModel) => ({
  user,
  organization,
});

const PrivateRoute = observer(({ component, ...rest }: Props) => {
  const { user } = useInject(mapStore);

  if (!component || !user.isSignedIn) return null;

  const Component = component;

  return (
    <Route
      {...rest}
      render={(routerProps) => (
        <MainLayout>
          <Component {...routerProps} />
        </MainLayout>
      )}
    />
  );
});

export default PrivateRoute;
