import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import * as Sentry from '@sentry/react';

import App from './App';

import './styles/index.scss';
import './config/initFirebase';

Sentry.init({
  dsn: 'https://13821d959c3a4f10944bb8ef579d034d@o410040.ingest.sentry.io/5283616',
  environment: process.env.NODE_ENV,
});

// Disable react dev tools to make it difficult for hackers to access the store and explore protected routes
if (process.env.NODE_ENV === ('production' || 'staging')) {
  disableReactDevTools();
}

ReactDOM.render(<App />, document.getElementById('root'));
