import React from 'react';
import { observer } from 'mobx-react-lite';

import { useFirebaseMobx } from '@src/components/utils/Mobx';

import CancelConfirmButtons from '@src/components/CancelConfirmButtons';

import Modal from './Modal';

const ChangeStatusModal = observer((props) => {
  const store = useFirebaseMobx();

  const fullName = `${props.userProperties.firstName} ${props.userProperties.lastName}`;
  const { toStatus } = props.userProperties;

  const onConfirm = async () => {
    store.updateUserByEmail(props.userProperties.email, { disabled: toStatus === 'deactivated' });
    props.onRequestClose();
  };

  return (
    <Modal
      title="Confirm Change"
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      containerClass="change-status-modal-container"
    >
      <p>
        Please confirm that you would like to change the status of <b>{fullName}</b> to <b>{toStatus}</b>.
      </p>

      <CancelConfirmButtons cancel={props.onRequestClose} confirm={onConfirm} />
    </Modal>
  );
});

export default ChangeStatusModal;
