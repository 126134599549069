import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import profile from '@src/assets/user.png';
import cwLogo from '@src/assets/covid-watch-logo-blue.svg';
import menu from '@src/assets/menu.svg';

import * as ROLES from '@src/constants/roles';
import * as ROUTES from '@src/constants/routes';

import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { useInject } from '@src/utils/hooks/mobx';
import { RootStoreModel } from '@src/mobx/stores/Root';

const mapStore = ({ user, organization, auth }: RootStoreModel) => ({
  user,
  organization,
  signOut: auth.signOut.signOutEffect,
});

const LoggedInIcons = observer(({ user }: { user: RootStoreModel['user'] }) => {
  const { signOut, organization } = useInject(mapStore);

  const { push: navigate } = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const onOpenMenu = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = React.useCallback(
    (num: number) => {
      setAnchorEl(null);

      switch (num) {
        case 0:
          navigate(ROUTES.CODE_VALIDATIONS);
          break;
        case 1:
          navigate(ROUTES.MANAGE_MEMBERS);
          break;
        case 2:
          navigate(ROUTES.BRANDING);
          break;
        case 3:
          navigate(ROUTES.SETTINGS);
          break;
        case 4:
          // Sign out, and route protection system will automatically push user to ROUTES.LOGIN
          signOut();
          break;
        case 5:
          navigate(ROUTES.MANAGE_IN_APP_MESSAGING);
          break;
        default:
      }
    },
    [setAnchorEl, navigate, signOut]
  );

  return (
    <div id="logged-in-icons-container">
      <div className="avatar_group avatar_text">
        <div className="small-text name">{`${user.prefix} ${user.firstName} ${user.lastName}`}</div>
        <div className="xs-text">{user.isAdmin ? ROLES.ADMIN_LABEL : ROLES.NON_ADMIN_LABEL}</div>
        <div className="xs-text">{organization.name}</div>
      </div>
      <div className="avatar_group avatar_image">
        <Link to="/settings">
          <img src={user.imageBlob ? user.imageBlob : profile} className="profile_photo" alt="" />
        </Link>
      </div>
      <div className="avatar_group separator" />

      <IconButton edge="start" className="menu-btn" color="inherit" aria-label="menu" onClick={onOpenMenu}>
        <img src={menu} alt="Menu" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user.isAdmin && (
          <MenuItem
            style={{ marginTop: 22 }}
            id="mobile-app-settings"
            className="menu-link"
            onClick={() => navigate(ROUTES.DASHBOARD)}
          >
            Dashboard
          </MenuItem>
        )}

        <MenuItem id="diagnosis-verification-codes" className="menu-link" onClick={() => onClickMenuItem(0)}>
          Diagnosis Verification Codes
        </MenuItem>
        {user.isAdmin && (
          <MenuItem id="manage-members" className="menu-link" onClick={() => onClickMenuItem(1)}>
            Manage Members
          </MenuItem>
        )}
        <MenuItem id="mobile-app-settings" className="menu-link" onClick={() => onClickMenuItem(2)}>
          Mobile App Settings
        </MenuItem>
        {user.isSuperAdmin && (
          <MenuItem id="in-app-messaging-tool" className="menu-link" onClick={() => onClickMenuItem(5)}>
            Manage | In-App Messaging
          </MenuItem>
        )}
        <MenuItem id="in-app-messaging-update-history" className="menu-link" onClick={() => onClickMenuItem(3)}>
          My Settings
        </MenuItem>
        <MenuItem id="logout" className="menu-link" style={{ marginBottom: 22 }} onClick={() => onClickMenuItem(4)}>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
});

const Navbar = observer(() => {
  const { user, organization } = useInject(mapStore);

  const displayAsIfLoggedIn = React.useMemo(() => {
    return user.isSignedIn;
  }, [user.isSignedIn, user.passwordResetRequested, user.signedInWithEmailLink]);

  return (
    <div className="navbarContainer">
      <Link to="/" className="logo-link">
        <img src={organization.logoBlob || cwLogo} id="orgLogo" alt="Logo" />
      </Link>
      {displayAsIfLoggedIn ? <LoggedInIcons user={user} /> : <div id="logged-in-icons-container" />}
    </div>
  );
});

export default Navbar;
