import Firebase from 'firebase';
import app from 'firebase/app';

import firebaseConfigDev from '@src/lib/firebase/config/firebase.config.dev';
import firebaseConfigProd from '@src/lib/firebase/config/firebase.config.prod';
import firebaseConfigStaging from '@src/lib/firebase/config/firebase.config.staging';

const firebaseConfigMap: { [key: string]: any } = {
  local: firebaseConfigDev,
  development: firebaseConfigDev,
  production: firebaseConfigProd,
  staging: firebaseConfigStaging,
};

const currentEnv = process.env.NODE_ENV || 'development';

const config = firebaseConfigMap[currentEnv];

const firebaseApp = !Firebase.apps.length ? Firebase.initializeApp(config) : Firebase.app();

const { SESSION, NONE } = app.auth.Auth.Persistence;

const auth = Firebase.auth();
const db = Firebase.firestore();
const analytics = process.env.NODE_ENV !== 'test' ? app.analytics() : {};

const createUserCallable = app.functions().httpsCallable('createUser');
const initiatePasswordRecoveryCallable = app.functions().httpsCallable('initiatePasswordRecovery');
const getVerificationCodeCallable = app.functions().httpsCallable('getVerificationCode');

export {
  auth,
  db,
  SESSION,
  NONE,
  analytics,
  createUserCallable,
  initiatePasswordRecoveryCallable,
  getVerificationCodeCallable,
};

export default firebaseApp;
