import React from 'react';
import styled from 'styled-components';

import ReactModal from 'react-modal';

import close from '@src/assets/close.svg';

type Props = {
  onRequestClose: () => void;
  className?: string;
  title?: string;
  appElementSelector?: keyof HTMLElementTagNameMap;
  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
  withTemplate?: boolean;
};

const ModalContainer = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalInnerContent = styled.div`
  flex: 1;
`;

const CloseButton = styled.button`
  padding: 5px;
  background: none;
  border: none;
  cursor: pointer;
`;

const ModalAdapter: React.FC<Props> = ({
  appElementSelector = 'body',
  shouldCloseOnOverlayClick = true,
  children,
  onRequestClose,
  className,
  withTemplate = true,
  title,
  ...other
}) => {
  React.useLayoutEffect(() => {
    ReactModal.setAppElement(document.querySelector(appElementSelector) as HTMLElement);
  }, [appElementSelector]);

  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      onRequestClose={onRequestClose}
      shouldReturnFocusAfterClose={false}
      closeTimeoutMS={150}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      {...other}
    >
      {withTemplate ? (
        <ModalContainer>
          <ModalHeader>
            <h3>{title}</h3>
            <CloseButton onClick={onRequestClose} type="button">
              <img src={close} alt="Close" />
            </CloseButton>
          </ModalHeader>
          <ModalInnerContent>{children}</ModalInnerContent>
        </ModalContainer>
      ) : (
        children
      )}
    </ReactModal>
  );
};

export default ModalAdapter;
