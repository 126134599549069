import React from 'react';
import { observer } from 'mobx-react-lite';

import * as ROLES from '@src/constants/roles';

import { useFirebaseMobx } from '@src/components/utils/Mobx';

import CancelConfirmButtons from '@src/components/CancelConfirmButtons';

import Modal from './Modal';

const ChangeRoleModal = observer((props) => {
  const store = useFirebaseMobx();

  const fullName = `${props.userProperties.firstName} ${props.userProperties.lastName}`;
  const fromRole = props.userProperties.isAdmin ? ROLES.ADMIN_LABEL : ROLES.NON_ADMIN_LABEL;
  const toRole = props.userProperties.isAdmin ? ROLES.NON_ADMIN_LABEL : ROLES.ADMIN_LABEL;

  const onConfirm = async () => {
    store.updateUserByEmail(props.userProperties.email, { isAdmin: !props.userProperties.isAdmin });
    props.onRequestClose();
  };

  return (
    <Modal title="Confirm Change" isOpen={props.isOpen} onClose={props.onRequestClose}>
      <p>
        Please confirm that you would like to change the role of <b>{fullName}</b>.
      </p>
      <p className="role-change">
        From: <b>{fromRole}</b>
        <br />
        To: <b>{toRole}</b>
      </p>

      <CancelConfirmButtons cancel={props.onRequestClose} confirm={onConfirm} />
    </Modal>
  );
});

export default ChangeRoleModal;
