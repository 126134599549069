/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

import * as ROUTES from '@src/constants/routes';

import Students from '@src/assets/students.svg';

import { RootStoreModel } from '@src/mobx/stores/Root';

import { useInject } from '@src/utils/hooks/mobx';

import Toast from '@src/components/Toast';
import Button from '@src/components/buttons/Button';
import PageTitle from '@src/components/PageTitle';

import ChangePasswordModal from '@src/components/modals/ChangePasswordModal';
import ForgotPasswordModal from '@src/components/modals/ForgotPasswordModal';

const DEFAULT_FORM_VALUES = {
  email: '',
  password: '',
};

const StyledButton = styled(Button)`
  margin-top: 30px;
`;

const ForgotPasswordButton = styled.button`
  border: none;
  margin-top: 30px;
  color: ${(p) => p.theme.colors.bluejay};
  padding: 5px;
  width: max-content;
  align-self: center;
  cursor: pointer;
`;

const mapStore = ({ auth, user }: RootStoreModel) => ({
  signIn: auth.signIn.signInEffect,
  signInWithEmailLink: auth.signIn.signInWithEmailLink,
  isLoading: auth.signIn.isLoading,
  errorMessage: auth.signIn.errorMessage,
  dismissMessages: auth.signIn.dismissMessages,
  user,
});

const Login = observer(() => {
  const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = React.useState(false);
  const { signIn, errorMessage, isLoading, dismissMessages, user, signInWithEmailLink } = useInject(mapStore);
  const { register, handleSubmit } = useForm({ defaultValues: DEFAULT_FORM_VALUES });
  const errorToastRef = React.useRef<any>(null);

  React.useEffect(() => {
    signInWithEmailLink();
  }, []);

  React.useEffect(() => {
    return () => {
      dismissMessages();
    };
  }, [dismissMessages]);

  React.useEffect(() => {
    if (errorMessage && errorToastRef.current) {
      errorToastRef.current.show();
    }
  }, [errorMessage]);

  const onSignIn = React.useCallback((values: Parameters<typeof signIn>[0]) => signIn(values), [signIn]);

  if (user.isSignedIn && !user.passwordResetRequested && !user.isFirstTimeUser) {
    return <Redirect to={ROUTES.CODE_VALIDATIONS} />;
  }

  return (
    <>
      <PageTitle title="Welcome" />
      <div className="module-container module-container-login">
        <div className="mainContainer">
          <div className="welcome">
            <h1>WeHealth Portal</h1>
            <h3 className="small-text welcome-blurb-desktop">
              Welcome to the Portal where your team can generate diagnosis verification codes to share with patients who
              test positive for COVID-19. With your help, they can decrease their risk to others by sharing a positive
              diagnosis, which allows the app to anonymously notify those who were nearby when the patient was likely
              infectious. <a href="https://www.wehealth.org">Learn more</a>.
            </h3>

            <div id="students-container">
              <img alt="Students" src={Students} />
            </div>
          </div>
          <form className="loginContainer" onSubmit={handleSubmit(onSignIn)}>
            <label className="small-text" htmlFor="email">
              Email Address
            </label>
            <input ref={register} type="email" id="email" name="email" />
            <label className="small-text" htmlFor="password">
              Password
            </label>
            <input ref={register} type="password" id="password" name="password" />
            <StyledButton type="submit" isLoading={isLoading} size="large">
              Login
            </StyledButton>
            <ForgotPasswordButton type="button" onClick={() => setForgotPasswordModalOpen((s) => !s)}>
              Forgot password?
            </ForgotPasswordButton>
          </form>
          <h3 className="small-text welcome-blurb-mobile">
            <a href="https://www.wehealth.org">Learn more</a>.
          </h3>
        </div>
        <Toast ref={errorToastRef} isSuccess={false} message={errorMessage} />
      </div>
      <ForgotPasswordModal
        isOpen={isForgotPasswordModalOpen}
        onToggleModal={() => setForgotPasswordModalOpen((s) => !s)}
      />
      {user.passwordResetRequested && user.signedInWithEmailLink && (
        <ChangePasswordModal visible heading="Reset Password" subHeading="" />
      )}
      {user.isFirstTimeUser && (
        <ChangePasswordModal
          visible
          heading="Welcome!"
          subHeading="To make your account secure, please create a new password to replace the temporary password you were given in the email invitation."
        />
      )}
    </>
  );
});

export default Login;
