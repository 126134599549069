import { types, Instance } from 'mobx-state-tree';

import { Auth } from './Auth';
import { User } from './User';
import { Organization } from './Organization';

const RootStore = types.model('RootStore', {
  auth: Auth,
  user: User,
  organization: Organization,
});

export type RootStoreModel = Instance<typeof RootStore>;

export default RootStore;
