import styled from 'styled-components';

import Modal from './Modal';

const StyledModal = styled(Modal)`
  z-index: 40;
  position: fixed;
  width: 100%;

  &__overlay {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 100vw;
    max-height: 100vh;
    background-color: rgba(0, 0, 0, 0) !important;
    transition: background-color 0.15s ease-in, opacity 0.15s linear;
    z-index: 40;

    &.ReactModal__Overlay--after-open {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: rgba(54, 75, 77, 0.5) !important;
    }
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }

    @media screen and (min-width: 768px) {
      padding-top: 150px;
      overflow-y: auto;
    }
  }

  &__content {
    flex: 1;
    flex-basis: auto;
    align-items: flex-start;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity 0.15s linear, transform 0.15s ease-out;
    position: static !important;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
    overflow-y: auto;
    transform: translate3d(0, -25%, 0);
    background: ${p => p.theme.colors.white};
    border-radius: 10px;

    &.ReactModal__Content--before-close {
      transform: translate3d(0, -10%, 0) !important;
    }

    &.ReactModal__Content--after-open {
      outline: none;
      opacity: 1;
      transform: translateZ(0);
    }

    @media screen and (min-width: 768px) {
      max-width: 450px;
      min-height: 100px;
      width: auto;
      height: auto;
      margin-bottom: 60px;
      overflow-y: visible;
    }
  }
`;

export default StyledModal;
