import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { AnimatedLoader } from '@src/assets';

type StyledProps = {
  size: 'small' | 'medium' | 'large';
  variant: 'primary' | 'secondary' | 'tertiary';
  isLoading?: boolean;
};

type Props = {
  isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  Partial<StyledProps>;

const StyledAnimatedLoader = styled(AnimatedLoader)`
  height: 100%;
  width: 100%;
  fill: ${(p) => p.theme.colors.white};
`;

const buttonSizes = variant({
  prop: 'size',
  variants: {
    small: {
      width: '60px',
      height: '25px',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '25px',
      borderWidth: '1.5px',
    },
    medium: {
      fontWeight: '600',
      fontSize: '16px',
      height: '35px',
      lineHeight: '20px',
      borderWidth: '2px',
      padding: '0px 20px',
    },
    large: {
      fontWeight: '600',
      fontSize: '16px',
      height: '50px',
      lineHeight: '20px',
      borderWidth: '2px',
      padding: '0px 20px',
    },
  },
});

const buttonVariants = variant({
  variants: {
    primary: {
      backgroundColor: 'bluejay',
      color: 'white',
      borderColor: 'bluejay',
    },
    secondary: {
      backgroundColor: 'white',
      color: 'bluejay',
      borderColor: 'bluejay',
    },
    tertiary: {
      backgroundColor: 'white',
      color: 'bluejay',
      borderColor: 'lightMediumGray',
    },
  },
});

const StyledButton = styled.button<StyledProps>`
  ${buttonVariants};
  ${buttonSizes};
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  text-align: center;
  border-style: solid;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.lightMediumGray};
  }
`;

const Button: React.FC<Props> = ({
  children,
  isLoading = false,
  variant: buttonType = 'primary',
  type = 'button',
  size = 'medium',
  ...rest
}) => {
  return (
    <StyledButton type={type} variant={buttonType} isLoading={isLoading} size={size} {...rest}>
      {isLoading ? <StyledAnimatedLoader tabIndex={-2} /> : children}
    </StyledButton>
  );
};

export default Button;
