module.exports = {
  apiKey: 'AIzaSyCSZmv-zvfOtlCRL2YZeD8_6Ht0b4eLd38',
  authDomain: 'wehealth-portal-staging.firebaseapp.com',
  databaseURL: 'https://wehealth-portal-staging.firebaseio.com',
  projectId: 'wehealth-portal-staging',
  storageBucket: 'wehealth-portal-staging.appspot.com',
  messagingSenderId: '366973817571',
  appId: '1:366973817571:web:31ba2600fff02b36d36bd6',
  measurementId: 'G-DX5B78PEG6',
};
