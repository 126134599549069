import React from 'react';
import styled from 'styled-components';
import media from 'css-in-js-media';

import chevronDown from '@src/assets/chevron-down.svg';

const StyledSelect = styled.select`
  width: 350px;
  height: 40px;
  border: 1px solid ${(p) => p.theme.colors.lightMediumGray};
  border-radius: 2px;
  font-size: 16px;
  padding-left: 20px;
  appearance: none;
  background: url(${chevronDown}) no-repeat 95% 50%;
  background-size: 15px 15px;
  background-color: white;

  ${media('<=phone')} {
    width: 100%;
  }

  &:disabled {
    background-color: ${(p) => p.theme.colors.lightGray};
    border-color: ${(p) => p.theme.colors.lightMediumGray};
  }
`;

const renderPlaceholder = (placeholder) => {
  if (!placeholder) return null;
  return (
    <option disabled value="">
      {placeholder}
    </option>
  );
};

const Select = ({ options, placeholder, selectRef = undefined, onChange = undefined, ...other }) => {
  return (
    <StyledSelect
      defaultValue=""
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
        return e;
      }}
      ref={selectRef}
      {...other}
    >
      {renderPlaceholder(placeholder)}
      {options.map((option) => (
        <option
          value={option.value}
          key={option.value}
          disabled={typeof option.disabled === 'boolean' ? option.disabled : false}
        >
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};

export default Select;
