import { types, cast, Instance } from 'mobx-state-tree';

import { User } from './User';

export const PAGE_SIZE = 15;

export const Organization = types
  .model({
    id: types.string,
    name: types.string,
    refID: types.string,
    welcomeText: types.string,
    members: types.array(User),
    membersPage: types.number,
    logoBlob: types.maybeNull(types.string),
  })
  .actions((self) => ({
    // TODO: Use a better way to update organizations and use types here as well
    update: (updates: any) => {
      Object.keys(updates).forEach((key) => {
        // @ts-ignore
        if (self.hasOwnProperty(key)) self[key] = updates[key]; // eslint-disable-line
      });
    },
    setMembers: (members: any) => {
      self.members = cast(members);
    },
    setMembersPage: (page: any) => {
      self.membersPage = page;
    },
  }))
  .views((self) => ({
    get currentPageOfMembers() {
      return self.members.slice((self.membersPage - 1) * PAGE_SIZE, (self.membersPage - 1) * PAGE_SIZE + PAGE_SIZE);
    },
    get totalPagesOfMembers() {
      return Math.ceil(self.members.length / PAGE_SIZE);
    },
  }));

export const defaultOrganization = {
  id: '',
  name: '',
  refID: '',
  welcomeText: '',
  members: [],
  membersPage: 1,
  logoBlob: null,
};

export type OrganizationModel = Instance<typeof Organization>;
