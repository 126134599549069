/* eslint-disable jsx-a11y/click-events-have-key-events  */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */
/* eslint-disable jsx-a11y/anchor-is-valid  */

import React, { useRef, useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as ROLES from '@src/constants/roles';
import * as ROUTES from '@src/constants/routes';

import photo_add from '@src/assets/photo-add.svg';

import { useFirebaseMobx } from '@src/components/utils/Mobx';

import Modal from '@src/components/modals/Modal';
import Toast from '@src/components/Toast';
import PageTitle from '@src/components/PageTitle';
import PendingOperationButton from '@src/components/PendingOperationButton';
import ResetPasswordModal from '@src/components/modals/ResetPasswordModal';

const MAXFILESIZE = 10 * 1024 * 1024;

// Breakpoints customized to match those of _include-media.scss
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 420,
      md: 768,
      lg: 1024,
      xl: 1920,
    },
  },
});
const Settings = observer(() => {
  const store = useFirebaseMobx();
  const imgUploader = useRef();

  const [open, setOpen] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [toastInfo, setToastInfo] = useState({
    success: false,
    msg: '',
  });
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();

  const resetPassword = async (e) => {
    e.preventDefault();
    setShowResetPasswordModal(true);
  };

  const onChange = async (event) => {
    if (event.target.name === 'prefix') {
      store.updateUser({ prefix: event.target.value });
    } else if (event.target.name === 'firstName') {
      if (event.target.value.length < 1) {
        setToastInfo({
          success: false,
          msg: 'First name must be at least one character',
        });
        toastRef.current.show();
      } else {
        store.updateUser({ firstName: event.target.value });
      }
    } else if (event.target.name === 'lastName') {
      if (event.target.value.length < 1) {
        setToastInfo({
          success: false,
          msg: 'Last name must be at least one character',
        });
        toastRef.current.show();
      } else {
        store.updateUser({ lastName: event.target.value });
      }
    }
  };

  const saveImage = async () => {
    if (imgUploader.current.files.length === 0) {
      // No image uploaded
      return;
    }

    setLoading(true);

    const file = imgUploader.current.files[0];

    try {
      const { size } = imgUploader.current.files[0];

      if (size > MAXFILESIZE) {
        setLoading(false);
        setToastInfo({
          success: false,
          msg: 'Exceeded Max Image file size. Image has to be less than 10MB',
        });
        toastRef.current.show();
        imgUploader.current.value = null;
      }
      const reader = new FileReader();
      // set up onload trigger to run when data is read
      reader.onload = async () => {
        return store
          .updateUserImage(file)
          .then(() => {
            setToastInfo({
              success: true,
              msg: 'Image Updated',
            });
            toastRef.current.show();
            setLoading(false);
            setOpen(false);
          })
          .catch(() => {
            setLoading(false);
            setToastInfo({
              success: false,
              msg: 'Error Updating Image, Please Try Again',
            });
            toastRef.current.show();
          });
      };
      // read data
      reader.readAsDataURL(imgUploader.current.files[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangePasswordSuccess = () => {
    setToastInfo({
      success: true,
      msg: 'Success: Password Reset',
    });
    toastRef.current.show();
    setShowResetPasswordModal(false);
  };

  const onChangePasswordFailure = (message) => {
    setToastInfo({
      success: false,
      msg: message,
    });
    toastRef.current.show();
  };

  const onChangePasswordClose = () => {
    setShowResetPasswordModal(false);
  };

  const changeImageModal = loading ? (
    <Modal
      title="Uploading image..."
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      containerClass="changeImageModalContainer"
    >
      <div className="save-image">
        <CircularProgress />
      </div>
    </Modal>
  ) : (
    <Modal
      title="Please Select a File to Upload"
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      containerClass="changeImageModalContainer"
    >
      <div>
        <input
          type="file"
          ref={imgUploader}
          accepts="image/jpeg, image/png"
          style={{ border: 'none', marginTop: '25px', marginBottom: '15px' }}
        />
        <PendingOperationButton operation={saveImage} className="save-button">
          Upload
        </PendingOperationButton>
      </div>
    </Modal>
  );

  const settingsForm = () => (
    <>
      <form>
        <Grid container id="settings-grid" spacing={2} direction="row" justify="center">
          <Grid item xs={12} md={12} lg={4} xl={2}>
            <Grid container spacing={2} direction="column" className="profile-photo-container">
              <label> Profile Photo </label>
              <div
                style={{
                  marginTop: '25px',
                  height: '217px',
                  width: '212px',
                  backgroundColor: '#E0E0E0',
                  border: '2px dashed #BDBDBD',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  alt={store.data.user.imageBlob ? 'Profile photo' : 'Your profile photo would go here.'}
                  src={store.data.user.imageBlob ? store.data.user.imageBlob : photo_add}
                  className="profile_photo"
                  style={
                    store.data.user.imageBlob
                      ? { width: 212, height: 217, resize: 'cover' }
                      : { width: 100, height: 102, paddingLeft: 10, paddingTop: 10 }
                  }
                  onClick={() => setOpen(true)}
                />
              </div>
              <div className="xs-text" style={{ marginTop: 10 }}>
                Accepted file format: jpg or png
              </div>
              <div className="xs-text">Maximum file size: 10 MB</div>
              {changeImageModal}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={4} xl={5}>
            <Grid container spacing={2} direction="column">
              <label htmlFor="prefix">Prefix</label>
              <input type="text" id="prefix" name="prefix" onChange={onChange} value={store.data.user.prefix} />
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                required
                aria-required="true"
                onChange={onChange}
                defaultValue={store.data.user.firstName}
              />
              <div className="email-container">
                <label htmlFor="email">Email Address</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  required
                  disabled
                  aria-required="true"
                  style={{ backgroundColor: '#e0e0e0' }}
                  value={store.data.user.email}
                />
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={4} xl={5}>
            <Grid container spacing={2} direction="column">
              <label htmlFor="role">Role</label>
              {store.data.user && (
                <input
                  type="text"
                  id="role"
                  name="role"
                  disabled
                  required
                  aria-required="true"
                  style={{ backgroundColor: '#e0e0e0' }}
                  value={store.data.user.isAdmin ? ROLES.ADMIN_LABEL : ROLES.NON_ADMIN_LABEL}
                />
              )}
              <div className="lastName-container">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  required
                  aria-required="true"
                  onChange={onChange}
                  defaultValue={store.data.user.lastName}
                />
              </div>
              <label htmlFor="password">Password</label>
              <input
                type="text"
                id="password"
                name="password"
                style={{ backgroundColor: '#e0e0e0', fontSize: '30px' }}
                disabled
                required
                aria-required="true"
                defaultValue=" • • • • • • • •"
              />
              <a href="" className="change-password-link" onClick={(e) => resetPassword(e)}>
                Change Password
              </a>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Toast ref={toastRef} isSuccess={toastInfo.success} message={toastInfo.msg} />
    </>
  );

  return !store.data.user.isSignedIn ||
    store.data.user.isFirstTimeUser ||
    (store.data.user.passwordResetRequested && store.data.user.signedInWithEmailLink) ? (
    <Redirect to={ROUTES.LANDING} />
  ) : (
    <MuiThemeProvider theme={theme}>
      <PageTitle title="My Settings" />
      <div className="module-container">
        <div className="settings-header">
          <h1 style={{ marginBottom: 12 }}>My Settings</h1>
          <p className="xs-text">Changes are automatically saved</p>
        </div>
        {settingsForm()}
        <ResetPasswordModal
          isOpen={showResetPasswordModal}
          onRequestClose={onChangePasswordClose}
          onSuccess={onChangePasswordSuccess}
          onFailure={onChangePasswordFailure}
        />
      </div>
    </MuiThemeProvider>
  );
});

export default Settings;
