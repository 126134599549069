import axiosInstance from '@src/utils/axios';
import * as _ from 'lodash';

import { AppSettings } from '@src/lib/types';

import DefaultAPIHandler from './DefaultAPIHandler';

const ai = axiosInstance();

const couldNotRetrieveInfoMsg = 'Could not retrieve information';

export const getVerificationCodes = async (realm: string, communityId: string): Promise<AppSettings[]> => {
  let url = `/analytics/verification-codes`;
  if (communityId) url += `/${communityId}`;

  try {
    const { data } = await ai.get(url);

    return data;
  } catch (e) {
    const err = e as Error;
    throw DefaultAPIHandler(err, couldNotRetrieveInfoMsg);
  }
};

export const getPositiveCases = async (realm: string, communityId: string): Promise<AppSettings[]> => {
  try {
    const { data } = await ai.get(`/analytics/positive-cases/${communityId}`);

    return data;
  } catch (e) {
    const err = e as Error;
    throw DefaultAPIHandler(err, couldNotRetrieveInfoMsg);
  }
};

export const getExposureNotifications = async (realm: string): Promise<AppSettings[]> => {
  try {
    const { data } = await ai.get(`/analytics/exposure-notifications`);
    return data;
  } catch (e) {
    const err = e as Error;
    throw DefaultAPIHandler(err, couldNotRetrieveInfoMsg);
  }
};

export const getBitlyClicks = async (type: string, communityId: string): Promise<AppSettings[]> => {
  let url = `/analytics/bitly-clicks/${type}`;
  if (communityId) url += `/${communityId}`;

  try {
    const { data } = await ai.get(url);

    return data;
  } catch (e) {
    const err = e as Error;
    throw DefaultAPIHandler(err, couldNotRetrieveInfoMsg);
  }
};

export const getAppAnalytics = async (realm: string): Promise<AppSettings[]> => {
  try {
    const { data } = await ai.get(`/analytics/opt-ins`);

    return data;
  } catch (e) {
    const err = e as Error;
    throw DefaultAPIHandler(err, couldNotRetrieveInfoMsg);
  }
};

export const getActiveUsers = async (realm: string): Promise<AppSettings[]> => {
  try {
    const { data } = await ai.get(`/analytics/active-users/community`);
    return data;
  } catch (e) {
    const err = e as Error;
    throw DefaultAPIHandler(err, couldNotRetrieveInfoMsg);
  }
};

export const getAppDownloads = async (realm: string): Promise<AppSettings[]> => {
  try {
    const { data } = await ai.get(`/analytics/app-downloads/daily`);
    return data;
  } catch (e) {
    const err = e as Error;
    throw DefaultAPIHandler(err, couldNotRetrieveInfoMsg);
  }
};
