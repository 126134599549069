import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    display: none;

    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const CheckboxWrapper = styled.label`
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  overflow: hidden;
  border: 2px solid ${p => p.theme.colors.lightMediumGray};
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ ${Checkmark} {
    background-color: ${p => p.theme.colors.lightGray};
  }

  input:checked ~ ${Checkmark} {
    background-color: ${p => p.theme.colors.bluejay};
  }

  input:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

const Checkbox = ({ className, ...rest }) => (
  <CheckboxWrapper tabIndex={-1} className={classnames('checkbox', className)}>
    <input type="checkbox" {...rest} />
    <Checkmark />
  </CheckboxWrapper>
);

export default Checkbox;
