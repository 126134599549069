import axios, { AxiosError, AxiosRequestConfig, AxiosInstance } from 'axios';
import Firebase from 'firebase/app';

import { API_URL, API_URL_PUBLIC } from '@src/config/api';

export const axiosErrorHandler = (e: AxiosError): void => {
  const error = new Error(`Axios errored at "${e.config?.method?.toUpperCase()} ${e.config.url}":
    ${e.message}`);

  if (!e.response?.status) throw error;
  throw e;
};

export const addHeaders = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const sproutConfig = config;
  const idToken = await Firebase.auth().currentUser?.getIdToken();

  sproutConfig.headers.common = {
    ...config.headers.common,
    ...(idToken && { Authorization: `Bearer ${idToken || ''}` }),
  };

  return sproutConfig;
};

export const createBaseRequestInterceptor =
  () =>
  (defaultConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const configWithHeaders = addHeaders(defaultConfig);
    return configWithHeaders;
  };

export default (): AxiosInstance => {
  const ai = axios.create({
    baseURL: API_URL,
  });

  ai.interceptors.request.use(createBaseRequestInterceptor(), axiosErrorHandler);

  return ai;
};

// axios instance for public API (api.wehealth.org/1/...)
export const axiosInstancePublicAPI = (): AxiosInstance => {
  const ai = axios.create({
    baseURL: API_URL_PUBLIC,
  });

  ai.interceptors.request.use(createBaseRequestInterceptor(), axiosErrorHandler);

  return ai;
};
