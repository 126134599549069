import axios from 'axios';
import { TypedQueryFunction } from 'react-query';
import { AppData } from '@src/lib/types';

import DefaultAPIHandler from './DefaultAPIHandler';

const ai = axios.create();

export async function get(dataBaseLink: string): Promise<AppData> {
  try {
    const { data } = await ai.get(`${dataBaseLink}.json`);
    return data;
  } catch (error) {
    throw DefaultAPIHandler(error, 'Could not retrieve information');
  }
}

export async function getIdMapping(dataBaseLink: string): Promise<String[]> {
  try {
    const { data } = await ai.get(`${dataBaseLink}/region-to-community-id-mapping.json`);
    return data;
  } catch (error) {
    throw DefaultAPIHandler(error, 'Could not retrieve information');
  }
}
