import { types, Instance, flow, getRoot } from 'mobx-state-tree';

import { auth } from '@src/config/initFirebase';

import { RootStoreModel } from './Root';

export type CredentialsType = {
  email: string;
  password: string;
};

export type PasswordResetType = {
  code: string;
  newPassword: string;
};

export const SignInAuth = types
  .model('SignInAuth', {
    isLoading: types.boolean,
    errorMessage: types.string,
  })
  .actions((self) => ({
    dismissMessages() {
      self.errorMessage = '';
    },
    signInEffect: flow(function* signInEffect({ email, password }: CredentialsType) {
      self.isLoading = true;
      self.errorMessage = '';
      try {
        yield auth.signInWithEmailAndPassword(email, password);

        self.isLoading = false;
      } catch (error) {
        if (error?.code === 'auth/user-not-found') {
          self.errorMessage = 'The email address or password you entered is incorrect.';
        } else {
          self.errorMessage = error.message;
        }
        self.isLoading = false;
      }
    }),
    signInWithEmailLink: flow(function* signInWithEmailLinkEffect() {
      try {
        if (auth.isSignInWithEmailLink(window.location.href)) {
          // Get the email if available. This should be available if the user completes
          // the flow on the same device where they started it.
          let email = window.localStorage.getItem('emailForSignIn');
          if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt('Please provide your email for confirmation') as string;
          }
          yield auth.signInWithEmailLink(email, window.location.href);
          window.localStorage.removeItem('emailForSignIn');
          const rootStore = getRoot<RootStoreModel>(self);
          rootStore.user.update({ signedInWithEmailLink: true });
        }
      } catch (error) {
        if (error.code === 'auth/expired-action-code') {
          self.errorMessage =
            'This magic link has expired. Please sign in with your password or restart the password recovery process.';
        } else {
          // Neither auth/invalid-email nor auth/user-disabled should happen
          self.errorMessage = 'Invalid magic link';
        }
      }
    }),
    signInSuccess() {
      self.isLoading = false;
      self.errorMessage = '';
    },
  }));

export const SignOutAuth = types
  .model('SignOutAuth', {
    isLoading: types.boolean,
    errorMessage: types.string,
  })
  .actions((self) => ({
    dismissMessages() {
      self.errorMessage = '';
    },
    signOutEffect: flow(function* signOutEffect() {
      self.isLoading = true;
      self.errorMessage = '';
      try {
        yield auth.signOut();
        self.isLoading = false;
      } catch (error) {
        self.isLoading = false;
        self.errorMessage = error.message;
      }
    }),
  }));

export const Auth = types.model('Auth', {
  signOut: SignOutAuth,
  signIn: SignInAuth,
});

export const defaultAuth = {
  signIn: {
    isLoading: false,
    errorMessage: '',
  },
  signOut: {
    isLoading: false,
    errorMessage: '',
  },
};

export type SignOutAuthModel = Instance<typeof SignOutAuth>;
export type SignInAuthModel = Instance<typeof SignInAuth>;

export type AuthModel = Instance<typeof Auth>;
