import { types, Instance } from 'mobx-state-tree';

export const User = types
  .model({
    isSignedIn: types.maybe(types.boolean), // frontend-only field
    email: types.string,
    isAdmin: types.boolean,
    isSuperAdmin: types.maybe(types.boolean),
    disabled: types.boolean,
    prefix: types.maybe(types.string),
    imageBlob: types.maybeNull(types.string),
    firstName: types.string,
    lastName: types.string,
    organizationID: types.string,
    isFirstTimeUser: types.boolean,
    // NOTE: passwordResetRequested should typically be checked along with signedInWithEmailLink when used in conditionals
    // e.g. `if (passwordResetRequested && signedInWithEmailLink) ...`
    // Such conditionals are only met when the user requested a password reset AND they themselves clicked on the
    // magic link in the email. This protects against attacks where a malicious third party locks a user out of the portal
    // (or makes it exceedingly difficult to use) by repeatedly entering a legitimate user's email into the `Forgot password?` dialog.
    passwordResetRequested: types.maybe(types.boolean),
    signedInWithEmailLink: types.maybe(types.boolean), // frontend-only field
    passwordResetCompletedInCurrentSession: types.maybe(types.boolean), // frontend-only field
    isLoading: types.maybe(types.boolean),
  })
  .actions((self) => ({
    // TODO: Use a better way to update users and use types here as well
    update: (updates: any) => {
      Object.keys(updates).forEach((key) => {
        // @ts-ignore
        if (self.hasOwnProperty(key)) self[key] = updates[key]; // eslint-disable-line
      });
    },
  }));

export const defaultUser = {
  isSignedIn: false,
  email: '',
  isAdmin: false,
  isSuperAdmin: false,
  disabled: false,
  prefix: '',
  firstName: '',
  lastName: '',
  organizationID: '',
  imageBlob: null,
  isFirstTimeUser: false,
  passwordResetRequested: false,
  passwordResetCompletedInCurrentSession: false,
  signedInWithEmailLink: false,
  isLoading: false,
};

export type UserModel = Instance<typeof User>;
