import React from 'react';
import styled from 'styled-components';

import Footer from './components/Footer';
import Navbar from './components/Navbar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #f4f6f9;
`;

type Props = {};

const Public: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper className="flex flex-1 bg-neutral-97">
      <Navbar />
      <Main>{children}</Main>
      <Footer />
    </Wrapper>
  );
};

export default Public;
